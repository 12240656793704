import * as UI from "automaton-ui";
import API from "/scripts/API.js";

import * as Utils from "/scripts/Utils.js";

function modalTemplate(uuid){
	return [
		"Target Model:header",
		{
			key: 'model',
			type: 'list',
			options: async ()=>{
				let jobs = (await API.model().list()).sort(Utils.sortByName);
				let dataset = await API.dataset(uuid).get();
				return jobs
						// only allow evaluations on ready models
						.filter(j=>j.ready)
						// only allow evaluations on model with at least some class intersection
						.filter(j=>Utils.intersects(Object.keys(j.classes),Object.keys(dataset.classes)))
						.map(j=>{
							return {value: j.uuid, name: j.name ?? j.uuid};
						});
			}
		},
		"Dataset Configuration:header",
		{
			key: 'balance',
			type: 'compound',
			children: [
				{
					key: 'ratio',
					name: "Train/Val Split",
					type: 'list',
					format: 'number',
					options:  [
						{value:"80", name: "80 / 20"},
						{value:"100", name: "100 / 0 (full training)"},
						{value:"0", name: "0 / 100 (full evaluation)"}
					]
				},
				{
					key: 'shuffle',
					name: 'Shuffle',
					type: 'toggle',
					default: true,
					hidden: json => json.balance.ratio=='0' || json.balance.ratio=='100',
				},
				{
					key: 'class',
					name: "Class Balancing",
					type: 'compound',
					children: [
						{
							key: 'mode',
							name: "Mode",
							type: 'list',
							options:  [
								{value:"none", name: "None"},
								{value:"count", name: "Balance by Count"}
							]
						}
					],
					default: "none"
				}
			]
		},
		{
			key: 'evaluation_mode',
			name: 'Evaluation Mode',
			hidden: json => json.balance.ratio=='100',
			type: 'list',
			options:  [
				{value:"default", name: "Default"},
				{value:"class-overlap", name: "Class Overlap"}
			]
		}
	];
}

async function NewEvaluation(datasetuuid){
	return new Promise(resolve=>{
		//build the form
		let form = new UI.Form(modalTemplate(datasetuuid));
		// init with data
		form.build({balance: {
			ratio: "80/20",
			class: {
				mode: "count"
			}
		}});
		// push it into a modal
		let modal = new UI.Modal(form, {
			title: "New Evaluation",
			buttons: "<ui-cancel></ui-cancel><ui-spacer></ui-spacer>",
			dismissable: false
		}).attach();

		modal['close'] = ()=>{
			resolve(null);
			modal.self.remove();
			return modal;
		};

		modal.panel.footer(
			new UI.Button("Create", async ()=>{
				// prepare the config object
				let config = form.json(false);
				config.type = "Evaluation";
				config.scheduler = "AI_SCHEDULER";
				// request a new evaluation
				await API.dataset(datasetuuid).createJob(config);
				// indicate we're done
				resolve(null);
				modal.self.remove();
			}, {icon: 'fa-check', style: "create"})
		);
	});
}
export default NewEvaluation;