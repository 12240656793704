import * as UI from 'automaton-ui';
import API from '/scripts/API.js';

import NewTask from "/component/NewTask.js";
import WorldFilter from '/component/global/WorldFilter.js';

export class TaskList extends UI.BasicElement{

	constructor(){
		super();

		this.setAttribute('landing-list', '');

		this.init();
	}

	async init(){
		let json = await API.task().list();

		let list = new UI.List(task => {
			let panel = new UI.Panel('',{title: task.name, clazz: 'clickable'});
			if(task.type == 'bucket' || task.type == 'multi-class-polygon' || task.type == 'multi-class-box'){
				panel.append(...(task.buckets || []).map(s=>new UI.Badge(s.key)));
			}else{
				panel.append(task.type);
			}

			if(task.description){
				let text = document.createElement('pre');
				text.innerHTML = task.description;
				panel.append(text);
			}

			panel.addEventListener("click", ()=>{
				window.location.hash = `task/${task.uuid}`;
			});

			let menu = new UI.ContextMenu();
			menu.addItem("Delete", async ()=>{
				if(window.confirm("Really delete this task? This will delete all related labels!")){
					await API.task(task.uuid).delete();
					new UI.Toast("Task deleted");
					panel.remove();
				}else{
					new UI.Toast("Cancelled");
				}
			});
			menu.for(panel);

			return panel;
		});
		list.addAttribute("Name", "name");
		list.addAttribute("Created", "created");

		list.sort("Created", true);
		list.data = json;

		await WorldFilter.apply(list);

		list.render();

		this.append(list);

		let button = new UI.Button("New Task...", async ()=>{
			let task = await NewTask();
			if(task) {
				window.location.hash = `task/${task.uuid}`;
			}
		}, {style: "new-button"});

		this.append(button);
	}

}
window.customElements.define("ui-tasklist", TaskList);
export default TaskList;