import * as UI from 'automaton-ui';

class KeyboardShortcut extends UI.BasicElement{

	constructor(key){
		super();
		if(key==null){
			key = this.innerHTML.trim();
			if(this.innerHTML == "Space"){
				key = " ";
			}
		}else{
			this.innerHTML = text;
		}
		this.dataset.key=key;
	}

}
window.customElements.define("ui-key", KeyboardShortcut);

/** Stupidly simple keyboard listener; tries to find the related KeyboardShortcut hint element in the dom and click it! */
document.addEventListener("keyup", (event)=>{
	let key = event.key;
	/** @type {HTMLElement} */
	let element = document.body.querySelector(`ui-key[data-key='${key}']`);
	if(!element) {
		return;
	}
	element.click();
	element.classList.remove('pressed');
});

/** Stupidly simple keyboard listener; tries to find the related KeyboardShortcut hint element in the dom and click it! */
document.addEventListener("keydown", (event)=>{
	let key = event.key;
	let element = document.body.querySelector(`ui-key[data-key='${key}']`);
	if(!element) {
		return;
	}
	element.classList.add('pressed');
});

export default KeyboardShortcut;