import API from '/scripts/API.js';
import * as UI from "automaton-ui";
import WorldFilter from '/component/global/WorldFilter.js';
import * as Utils from "/scripts/Utils.js";

export default class JobList extends UI.BasicElement{

	constructor(){
		super();

		this.table = null;
		this.init();
	}

	async init(){
		let jobs = await API.job().list();

		let table = new UI.Table();
		table.addAttribute("Name", "name", job=>`<a href="${Utils.getUrl('model', job.model)}">${job.name}</a>`);
		// odd value function as sorting base16 numbers isn't something UI.List handles well!
		table.addAttribute("UUID", item=>parseInt(item.uuid.split('-')[0],16), "uuid");
		table.addAttribute('Type', 'type');
		table.addAttribute('Created', job=>job.created, job=>new Date(job.created).toISOString().replaceAll(/[TZ]/g, ' '));
		table.addAttribute('Author', 'author');
		table.addAttribute('Scheduler', 'scheduler');
		table.addAttribute('Status', 'status');
		table.addAttribute('Actions', null, (job)=>{
			return [new UI.Button("View",Utils.getUrl('job', job.uuid))];
		});

		table.sort('Created', false);

		await WorldFilter.apply(table);

		let rowFunc = table.getItemElement.bind(table);
		table.getItemElement = async (item)=>{
			let ele = await rowFunc(item);
			let menu = new UI.ContextMenu();
			let url = Utils.getUrl('job', item.uuid);
			menu.addItem("View", ()=>location.href = url);
			menu.addItem("View (new tab)", ()=>window.open(url));

			// allow retrying failed jobs
			if(item.status == "failed"){
				menu.addBreak();
				menu.addItem("Retry", async ()=>{
					try {
						let result = await API.job(item.uuid).retry();
						location.reload();
					} catch (e) {
						alert(e.message);
					}
				});
			}

			// allow the deletion of completed jobs
			if(item.status == "complete" || item.status == "failed"){
				menu.addBreak();
				menu.addItem("Delete", async ()=>{
					// give the user an option to bail
					if(!confirm("Really delete Job (Only this job will be deleted, Model, Evaluations and remote operations will remain)")) {
						return;
					}
					await API.job(item.uuid).delete();
					new UI.Toast("Job deleted");
					ele.remove();
				});
			}
			menu.for(ele);
			return ele;
		};

		table.data = jobs;
		table.render();

		this.append(table);

		this.table = table;

		this.setInterval(this.update.bind(this), 15_000);
	}

	async update(){
		let jobs = await API.background.job().list();
		this.table.data = jobs;
		this.table.render();
	}

}
window.customElements.define('ui-joblist', JobList);
