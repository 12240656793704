import CollectionPanel from "/component/collection/CollectionPanel.js"; // eslint-disable-line
import * as UI from "automaton-ui";
import API from "/scripts/API.js";

import dropBehaviour from "/scripts/DropBehaviour.js";
import * as Utils from "/scripts/Utils.js";

/**
 *
 * String to csv objects
 *
 * adapted from
 * https://stackoverflow.com/questions/1293147/example-javascript-code-to-parse-csv-data
 * https://gist.github.com/plbowers/7560ae793613ee839151624182133159
 *
 */
function csvStringToObjectsArray(strData){
	// horrible regexp to parse csv - TODO this code needs to be commented
	const objPattern = new RegExp(("(\\,|\\r?\\n|\\r|^)(?:\"((?:\\\\.|\"\"|[^\\\\\"])*)\"|([^\\,\"\\r\\n]*))"),"gi");
	let arrMatches = null;
	let arrData = [[]];
	while (arrMatches = objPattern.exec(strData)){ // eslint-disable-line
		if (arrMatches[1].length && arrMatches[1] !== ",") {
			arrData.push([]);
		}
		arrData[arrData.length - 1].push(arrMatches[2] ?
			arrMatches[2].replace(new RegExp( "[\\\\\"](.)", "g" ), '$1') :
			arrMatches[3]);
	}

	// filter out empty rows
	arrData = arrData.filter(row => row[0] != "");

	// map each row to the header values
	let hData = arrData.shift();
	let hashData = arrData.map(row => {
		let i = 0;
		return hData.reduce(
			(acc, key) => {
				acc[key] = row[i++];
				return acc;
			},
			{}
		);
	});
	return hashData;
}

/**
 *
 * A function which returns a function
 *
 * @param {CollectionPanel} collectionPanel
 */
export default function registerCollectionDropBehaviour(collectionPanel){
	/**
	 * @param {FileList} files
	 */
	async function process(files){
		if(files.length == 1 && files[0].name.endsWith('csv')){
			let csv = files[0];
			// parse the csv to items
			let text = await csv.text();
			let items = csvStringToObjectsArray(text);

			let sift = new ImageSifter(items, collectionPanel);
			sift.show();
		}else if(files.length == 1 && files[0].name.endsWith('zip')){
			let worker = await API.collection(collectionPanel.uuid).unpackZip(files[0]);
			new UI.Toast(`Created worker task ${worker} from zip file`);
			location.hash = Utils.getUrl('worker-queue');
		}else{
			// a bunch of images!
			let splash = new UI.Splash(`<progress max="${files.length}"></progress>`, {dismissable: false});
			let progress = splash.querySelector('progress');
			progress.value = 0;
			splash.show();

			let sourceIds = collectionPanel.collection.items.map(item=>item.sourceId);
			var duplicates = 0;
			for(let file of files){
				// skip duplicates
				if(sourceIds.includes(file.name)){
					duplicates += 1;
					continue;
				}

				// create the item
				await API.collection(collectionPanel.uuid).item(file, file.name);

				progress.value = progress.value+1;
			}

			// notify user on number of duplicates skipped
			if (duplicates > 0){
				new UI.Toast(`Skipped ${duplicates} item(s) with duplicate file names`, {level: 'warn'});
			}

			// redraw the panel
			collectionPanel.draw(true);
			splash.hide();
		}
	}

	// return this instance behaviour for attachment
	dropBehaviour(collectionPanel, process);
}

function getImageUrl(item){
	return item.location;
}

const ITEMS_PER_PAGE = 8;

class ImageSifter extends UI.Modal{

	collectionPanel;

	/**
	 *
	 * @param {Array} items
	 * @param {CollectionPanel} collectionPanel
	 */
	constructor(items, collectionPanel){
		super(null, {footer: true, header: true});

		this.collectionPanel = collectionPanel;

		/** @type {String[]} */
		let sourceIds = collectionPanel.collection.items.map(item=>item.sourceId);

		// remove items we wont upload because they are duplicates
		items = items.filter(item=>!sourceIds.includes(item.id));

		let list = new UI.List((item)=>{
			let url = getImageUrl(item);

			// create image
			let image = new Image();
			image.src = url;
			image.ondragstart = () => {
				return false;
			};
			let panel = new UI.Panel(image);

			// add event listeners
			image.onload = function() {
				let width = image.naturalHeight;
				let height = image.naturalHeight;

				// pre-disable low-resolution images
				if (width < 400 || height < 600) {
					console.log(`Excluding image '${url}' with size '${width}x${height}'`);
					item.exclude = true;
					panel.classList.toggle("exclude", item.exclude);
				}
			};
			panel.addEventListener("click", (e)=>{
				item.exclude = !item.exclude;
				panel.classList.toggle("exclude", item.exclude);
			});

			let showModal = (e)=>{
				let modal = new UI.Modal((`<img src="${url}"/>`));
				// prevent the contextmenu
				modal.addEventListener("contextmenu", (e)=>e.preventDefault());
				modal.show();
			};
			panel.addEventListener("contextmenu", showModal);
			panel.addEventListener("auxclick", showModal);

			return panel;
		}, {itemColumns: 4, itemsPerPage: ITEMS_PER_PAGE});
		list.data = items;
		list.render();

		let header = this.panel.querySelector('header');
		header.innerHTML = `${items.length} Items remaining`;

		this.panel.footer(new UI.Spacer(), new UI.Button("Submit Items", async ()=>{
			// grab the page of results
			let uploadItems = list.data.splice(0, ITEMS_PER_PAGE).filter(i=>!i.exclude);

			// upload the items
			if(uploadItems.length>0){
				let splash = new UI.Splash(`<progress max="${uploadItems.length}"></progress>`, {dismissable: false});
				let progress = splash.querySelector('progress');
				progress.value = 0;
				splash.show();

				for(let item of uploadItems){
					// create the item
					let blob = await API.proxy(getImageUrl(item));
					await API.collection(collectionPanel.uuid).item(blob, item.id);
					progress.value = progress.value+1;
				}
				splash.hide();
			}

			if(items.length == 0){
				// we're done redraw collection panel
				this.remove();
			}else{
				// redraw the sifter
				header.innerHTML = `${items.length} Items remaining`;
				list.dirty = true;
				list.page(0);
			}
		}, {icon: "fa-upload"}));

		this.panel.append(list);
	}

	remove(){
		this.collectionPanel.draw(true);
		super.remove();
	}

}
window.customElements.define("ui-imagesifter", ImageSifter);
