import * as UI from 'automaton-ui';
import API from '/scripts/API.js';
import NewWorld from "/component/NewWorld.js";
import WorldFilter from '/component/global/WorldFilter.js';

export class WorldList extends UI.BasicElement{

	constructor(){
		super();

		this.setAttribute('landing-list', '');

		this.init();
	}

	async init(){
		WorldFilter.reset();

		let json = await API.world().list();

		let list = new UI.List(world => {
			let panel = new UI.Panel(world.description, {
				title: world.name,
				clazz: 'clickable',
				footer: true
			});
			let href = `#world/${world.uuid}`;
			panel.addEventListener("click", (e)=>{
				if(e.ctrlKey){
					window.open(href);
				}else{
					window.location.hash = href;
				}
			});
			panel.addEventListener('auxclick', (e)=>{
				// middle click
				if(e.button == 1){
					window.open(href);
				}
			});
			panel.addEventListener('mousedown',(e)=>{
				if(e.button == 1){
					// on windows middlemouse down bring up the scroll widget; disable that
					e.preventDefault();
				}
			});

			//panel.footer(new UI.Button("Open", ()=>{}));

			return panel;
		}, {itemColumns: 3, itemsPerPage: 120});
		list.addAttribute("Name", "name");
		list.addAttribute("Created", "created");

		list.sort("Name", true);
		list.data = json;
		list.render();

		this.append(list);

		let button = new UI.Button("New World...", async ()=>{
			let world = await NewWorld();
			if(world) {
				window.location.hash = `world/${world.uuid}`;
			}
		}, {style: "new-button"});

		this.append(button);
	}

}
window.customElements.define("ui-worldlist", WorldList);
export default WorldList;
