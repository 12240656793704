import UI from "automaton-ui";
import API from "/scripts/API.js";
import TaskType from "/shared/domain/TaskType.js";

const BUCKET_TASK_TYPES = new Set([
	TaskType.BUCKET, TaskType.BOX_MULTI, TaskType.POLYGON_MULTI
]);

export const TASK_TEMPLATE = [
	{
		key :"name",
		type: "string",
		name: "name"
	},
	{
		"key": "type",
		"type": "list",
		"options": [
			{value: TaskType.BUCKET, name:"Classification"},
			{value: TaskType.BOX, name:"Bounding Boxes"},
			{value: TaskType.POLYGON, name:"Polygon Segmentations"},
			{value: TaskType.BOX_MULTI, name:"Bounding Boxes (multi class)"},
			{value: TaskType.POLYGON_MULTI, name:"Polygon Segmentations (multi class)"},
			{value: TaskType.FEATURE_VECTOR, name:"Feature Vector"}
		]
	},
	"description:text",
	{
		"key": "buckets",
		"name": "classes",
		"hidden": (json)=>!BUCKET_TASK_TYPES.has(json.type),
		"type": "array",
		"style": "ROW",
		"config": {
			"sortable": true
		},
		"children": [
			{
				key: "key",
				type: "string"
			},
			{
				key: "description",
				type: "text"
			}
		]
	}
];

export const MOBILENET_V1 = "MOBILENET_V1";

export async function getModelsForTaskType(type){
	let models = await API.model().list();
	let worlds = await API.world().list();

	// filter to models that are supported for our type
	models = filterModels(type, models);

	// only models that report ready!
	models = models.filter(m=>m.ready);

	let options = models.map(model=>({
		value: model.uuid,
		framework: model.framework,
		name: worlds.find(w=>w.uuid==model.world)?.name + " / " + model.name + ` (${model.accuracy})`,
		buckets: Object.keys(model.classes)
	}));

	options.sort((a,b)=>(a.name.localeCompare(b.name)));

	if(type == TaskType.FEATURE_VECTOR){
		// add the local mobilenet model
		options.unshift({value: MOBILENET_V1, name: MOBILENET_V1});
	}

	return options;
}

function filterModels(type, models){
	switch(type){
		case TaskType.FEATURE_VECTOR:
			// an embedding-service model has an extra endpoint that can provide a feature-vector
			return models.filter(m=>m.taskType == TaskType.FEATURE_VECTOR || m.framework == "embedding-service");
		case TaskType.BOX:
			return models.filter(m=>m.taskType == TaskType.BOX || m.taskType == TaskType.MASK);
		case TaskType.BUCKET:
			return models.filter(m=>m.taskType == TaskType.BUCKET);
	}
	return models;
}

/**
 * Detail panel for a Task
 */
class Task extends UI.BasicElement{

	constructor(uuid){
		super();

		this.uuid = uuid;

		this.init();
	}

	async init(){
		this.innerHTML = "";

		let json = await API.task(this.uuid).get();
		let panel = new UI.Panel(``, {title: `'${json.name}' Task`, footer: true});
		this.append(panel);

		let form = new UI.Form(TASK_TEMPLATE);
		form.build(json);
		panel.append(form);

		if(json.type == "polygon" || json.type == "box"){
			panel.footer(new UI.Button("Migrate to multi class", async ()=>{
				multiClassMigration(this, json);
			}, {icon: 'fa-cogs', color: 'create'}));
		}

		panel.footer(new UI.Spacer());

		panel.footer(new UI.Button("Save", async ()=>{
			let data = form.json();

			let json = {
				uuid: this.uuid,
				type: "Task",
				data: data
			};

			data = json.data;

			await API.task(this.uuid).update(data);

			location.href = '#task';
		}));
	}

}
window.customElements.define("ui-task", Task);
export default Task;

async function multiClassMigration(taskPanel, json){
	let classNameGuess = json.name.toLowerCase().split(" ")[0];
	if(classNameGuess.endsWith('s')){
		classNameGuess = classNameGuess.substring(0, classNameGuess.length-1);
	}

	let data = await UI.factory.popupForm([
		{
			key: "taskName",
			name: "Migrated Task name",
			type: "string",
			default: json.name +  " - Migrated"
		},
		{
			type: "description",
			key: `Choose a name to rename the current <pre style="display: inline-block; background:#ccc; padding: 3px;">instance</pre> segmentations to:`
		},
		{
			key: "className",
			name: "Migrated Class name",
			type: "string",
			default: classNameGuess
		}
	]);

	if(!data){
		return;
	}

	if(data.taskName == json.name){
		UI.warn(`You must provide a new task name`);
		return;
	}

	const BANNED_CLASS_NAMES = ['', 'instance'];
	if(BANNED_CLASS_NAMES.includes(data.className)){
		UI.warn(`'${data.className}' is not a permitted class name`);
		return;
	}

	let resp = await API.task(taskPanel.uuid).migrate(data.taskName, data.className);
	if(resp){
		UI.info("Migrated!");
		taskPanel.uuid = resp.task;
		await taskPanel.init();
	}
}
