import UI from "automaton-ui";
import API from "/scripts/API.js";

import * as CanvasUtils from "/scripts/Canvas.js";


export default class ModelTestPanel extends UI.BasicElement{

	constructor(uuid){
		super();

		this.dropSplash = new UI.Modal('<i class="fa fa-upload"></i>');
		this.dropSplash.classList.add('dropsplash');
		this.dropSplash.hide();
		this.uuid = uuid;
		this.ondragover=(event)=>{
			this.dropSplash.show();event.stopPropagation();event.preventDefault();
		};
		this.ondragleave=(event)=>{
			this.dropSplash.hide();
		};
		this.ondrop = (event)=>{
			event.stopPropagation();
			event.preventDefault();
			this.onImageDropped(event);
			this.dropSplash.hide();
		};
	}

	// Configure image drag and drop functionality
	async onImageDropped(event) {
		// read the blob data from the request
		let blob = null;

		let data = event.dataTransfer;
		// attempt to read file from drop event
		if(data.files.length==1){
			blob = data.files[0];
		}
		if(blob == null){
			// no file so attempt to read markup from drop event
			let types = data.types;
			if(types.includes("text/html")){
				let markup = data.getData("text/html");
				let wrapper = document.createElement('div');
				wrapper.innerHTML = markup;
				// parse the dropped markup an find a img tag in it
				// (which will typically be inside the dragged element)
				let img = wrapper.querySelector('img');
				if(img){
					// via the internal proxy grab the image
					console.log(img.src);
					blob = await API.proxy(img.src);
				}
			}
		}

		if(blob != null){
			// render the image
			let canvas = document.createElement('canvas');
			await CanvasUtils.blobToCanvas(blob, canvas, 1200);
			// convert to jpg
			blob = await CanvasUtils.canvasToBlob(canvas, 1.0);

			// get the classification
			let json = await API.model(this.uuid).test(blob);

			this.append(new ModelTestResult(canvas, json));
		}
	}

}
window.customElements.define("ui-modeltest", ModelTestPanel);

class ModelTestResult extends UI.Panel{

	constructor(canvas, json){
		super();

		document.body.querySelector('main > ui-hash > content').scrollTo(0,0);

		this.append(canvas);

		this.overlay = UI.html('<div class="overlay"></div>');
		this.append(this.overlay);

		this.width = canvas.width;
		this.height = canvas.height;

		this.style.width = canvas.width + "px";
		this.style.height = canvas.height + "px";

		// really basic classifier
		let classification = json['name'] ?? json['class_name'];
		if(classification){
			this.append(new UI.Badge(classification, {icon: 'fas fa-tag'}));
		}
		// either boxes or segmentations
		if(json.instances){
			json.instances = json.instances.sort((a,b)=>a.score - b.score);
			let id = 0;
			for(let instance of json.instances){
				instance.id = id++;
				let clazz = instance.class_name;
				let box = instance.bbox;

				// TODO - show segmentation mask if available
				let confidence = (instance.score*100).toFixed(1) + "%";
				this.plotBox(instance.id, `${clazz} (${confidence})`, box);
			}
		}
	}

	plotBox(itemid, type, xyxy){
		this.overlay.append(new BoundingBox(itemid, type, this.width, this.height, xyxy));
	}

}
window.customElements.define("ui-modeltestresult", ModelTestResult);

class BoundingBox extends UI.BasicElement{

	constructor(itemid, type, imageWidth, imageHeight, xyxy){
		super();
		this.setCss("--content", `'${type}'`);
		let borderWidth = 2; // border width in pixels
		this.setCss("--border-width", `${borderWidth}px`);
		this.classList.add('blurrable');
		// expand out slightly so the box doesn't hide what we are highlighting!
		let padding = borderWidth;
		this.setAttribute('item-id', itemid);
		this.style.left =  Math.max(0,(100 * (xyxy[0]-padding) / imageWidth)) + "%";
		this.style.top =  Math.max(0,(100 * (xyxy[1]-padding) / imageHeight)) + "%";
		this.style.right = Math.max(0,(100-(100 * ( xyxy[2]+padding) / imageWidth))) + "%";
		this.style.bottom = Math.max(0,(100-(100 * (xyxy[3]+padding) / imageHeight))) + "%";
	}

}
customElements.define('snap-box', BoundingBox);
