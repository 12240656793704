import * as UI from "automaton-ui";
import API from "/scripts/API.js";

/**
 * Component for reviewing the multiple labels created for a bucketing task
 *
 */
class ReviewGrid extends UI.BasicElement{

	constructor(collection, task){
		super();

		this.collectionUUID = collection;
		this.taskUUID = task;

		this.init();
	}

	async init(){
		// get all the collection
		let collection = await API.collection(this.collectionUUID).labels();

		// do a little prep on the items to allow easy access
		let items = collection.items;
		items.forEach(i=>{
			i.label = i.labels.filter(l=>l.task==this.taskUUID)?.[0];
			i.bucket = i.label?.data?.labels?.bucket;
		});
		items = items.filter(i=>i.bucket != null);

		// grab the task we are reviewing
		let task = await API.task(this.taskUUID).get();
		// list the possible label options
		let bucketOptions = task.buckets.map(b=>b.key);

		// build the filter form
		let form = new UI.Form([{
			key: 'bucket',
			name: task.name,
			type: 'select',
			options: bucketOptions
		},
		{
			key: 'passes',
			name: "Show Passes",
			type: 'toggle'
		},
		{
			key: 'fails',
			name: "Show Fails",
			type: 'toggle'
		}
		]);
		form.formStyle = UI.Form.STYLE.INLINE;
		form.build({
			bucket: bucketOptions[0],
			passes: false,
			fails: false
		});

		// build the display grid
		let list = new UI.List((item)=>{
			let panel = UI.utils.htmlToElement(
				`<div 
					oncontextmenu="return false"
					data-uuid="${item.uuid}"
					class="item ${item.label.data.reviewed=="PASS"?'pass':item.label.data.reviewed=="FAIL"?'fail':''}" 
					style="background-image: url('/image/${item.uuid}')">
				</div>`
			);
			// wire the review clicks
			panel.addEventListener('mousedown', (e)=>{
				e.stopPropagation();
				e.preventDefault();
				switch(e.button){
					case 0: // left mouse
						// pass (unless control is pressed - then fail)
						this.reviewItem(item, e.ctrlKey?"FAIL":"PASS");
						break;
					case 1: // middle mouse
						// show the image full size
						new UI.Modal(`<img src='/image/${item.uuid}'/>`).show();
						break;
					case 2: // right mouse
						// fail the item
						this.reviewItem(item, "FAIL");
						break;
				}
			});
			// create a zoom link
			let zoom = new UI.Button("", (e)=>{
				e.stopPropagation();
				new UI.Modal(`<img src='/image/${item.uuid}'/>`).show();
			}, {icon: 'fa-search-plus'});
			// prevent zoom clicks from going up to the main panel
			zoom.addEventListener('mousedown', (e)=>{
				e.stopPropagation();
			});
			panel.append(zoom);
			return panel;
		}, {itemColumns: 5, itemsPerPage: 10});

		// attach the filter function
		list._filterFunc = (i)=>{
			if(i.label.data.reviewed=="PASS" && !form.value.passes) {
				return false;
			}
			if(i.label.data.reviewed=="FAIL" && !form.value.fails) {
				return false;
			}
			return i.bucket==form.value.bucket;
		};
		// setup and render the item data
		list.data = items;
		this.append(list);

		// add a refresh button to redraw the grid
		let header = list.querySelector('header');
		header.prepend(new UI.Button("Refresh", ()=>{
			list.dirty = true;
			list.render();
		}, {icon: 'fa-sync'}));
		header.prepend(form);
		// hide the paging footer
		list.querySelector('footer').style.display = "none";

		// redraw the grid when the user changes the form
		form.addEventListener('change', ()=>{
			list.filter(list._filterFunc);
		});

		// add a select to choose grid size
		let sizer = UI.utils.htmlToElement(`<span><label>Grid Size</label><select class="size"></select></span>`);
		let size = sizer.querySelector('select');
		let options = [
			'1 x 1', '2 x 1', '3 x 1', '4 x 1', '5 x 1',
			'1 x 2', '2 x 2', '3 x 2', '4 x 2', '5 x 2',
			'1 x 3', '2 x 3', '3 x 3', '4 x 3', '5 x 3',
		];
		size.innerHTML = options.map(o=>`<option>${o}</option>`).join('');
		size.value = '5 x 2';
		size.addEventListener('change',(e)=>{
			let [cols,rows] = size.value.split(' x ').map(v=>parseInt(v));
			list.itemColumns = cols;
			list.itemsPerPage = cols * rows;
			list.dirty = true;
			list.render();
		});
		header.insertBefore(sizer, header.lastElementChild);
	}

	/**
	 *
	 * Update an item with a review status
	 *
	 * @param {*} item
	 * @param {"PASS"|"FAIL"|null} status
	 */
	reviewItem(item, status){
		// if we are pressing the button for the status
		// that is set already then remove it instead!
		if(item.label.data.reviewed == status) {
			status = null;
		}
		// find and update the current item element on the page
		let panel = this.querySelector(`[data-uuid="${item.uuid}"]`);
		if(panel){
			panel.classList.toggle("pass",status==="PASS");
			panel.classList.toggle("fail",status==="FAIL");
		}
		// add the review status to the local item data
		item.label.data.reviewed = status;
		// store the result to the server (as a background process)
		API.background.item(item.uuid).task(this.taskUUID).review(status);
	}

}

window.customElements.define("ui-reviewgrid", ReviewGrid);
export default ReviewGrid;
