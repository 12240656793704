import getCropTransform from "/component/evaluation/CropTransform.js";
import ItemEvaluationPanel from "/component/evaluation/ItemEvaluation.js";
import * as UI from "automaton-ui";
import API from "/scripts/API.js";

const ANY_CLASS = -1;
export default class EvaluationClassResults extends UI.BasicElement{

	constructor(mode, classes, results, datasetUUID, model){
		super();

		this.mode = mode;
		this.classes = classes;
		this.results = results;
		this.datasetUUID = datasetUUID;
		this.model = model;

		this.init();
	}

	async init(){
		// a lookup for the class by value
		const classmap = [];
		Object.keys(this.classes).forEach(key=>classmap[this.classes[key]]= key);

		// build a classes options obect for the filter
		let formClasses = [{
			value: ""+ANY_CLASS,
			name: "Any Class"
		}];
		for(let clazz of Object.keys(this.classes)){
			formClasses.push({
				value: ""+this.classes[clazz],
				name: clazz
			});
		}

		// build a form to configure filters
		let filterForm = new UI.Form([
			{
				key: 'showCorrectToggle',
				name: "<i class='fas fa-check'></i> Correct Values",
				type: 'list',
				format: 'number',
				options: [
					{value: '0', name: "Hide"},
					{value: '1', name: "Show"}
				]
			},
			{
				key: 'truth',
				name: "<i class='fas fa-tag'></i> Truth",
				type: 'list',
				format: 'number',
				options: formClasses
			},
			{
				key: 'prediction',
				name: "<i class='fas fa-question'></i> Prediction",
				type: 'list',
				format: 'number',
				options: formClasses
			}
		]);
		filterForm.formStyle = UI.Form.STYLE.INLINE;
		await filterForm.build({
			showCorrectToggle: 1,
			prediction: ANY_CLASS,
			truth: ANY_CLASS
		});

		// add the filer header to the element
		let filterElement = UI.utils.htmlToElement("<header class='filter'></header>");
		filterElement.append(filterForm);
		this.append(filterElement);

		// create a function that filters an item based on our configuration
		let filterFunction = (item)=>{
			let filterObject = filterForm.value;
			// don't include correct items if configured
			if(filterObject.showCorrectToggle==0 && item.score==1){
				return false;
			}

			// early out
			if(filterObject.prediction == ANY_CLASS && filterObject.truth == ANY_CLASS) {
				return true;
			}
			// filter by prediction/truth
			for(let annotation of item.annotations){
				if(filterObject.prediction != ANY_CLASS && filterObject.truth != ANY_CLASS){
					if(annotation.prediction?.class == filterObject.prediction
						&& annotation.truth.class == filterObject.truth) {
						return true;
					}
				}else if(filterObject.prediction != ANY_CLASS){
					if(annotation.prediction?.class == filterObject.prediction) {
						return true;
					}
				}else if(filterObject.truth != ANY_CLASS){
					if(annotation.truth.class == filterObject.truth) {
						return true;
					}
				}
			}
			return false;
		};

		// build a transformer for the crop
		let datasetAnnotations = await API.dataset(this.datasetUUID).annotations();
		let cropTransform = getCropTransform(this.model?.config);

		// create a list of the items
		let list = new UI.List((item)=>new ItemEvaluationPanel(this.mode, item, classmap, true, datasetAnnotations.find(a=>a.image_id==item.uuid), cropTransform), {itemColumns: 3, itemsPerPage: 12});
		list.data = this.results;

		// odd value function as sorting base16 numbers isn't something UI.List handles well!
		list.addAttribute("UUID", item=>parseInt(item.uuid.split('-')[0],16));

		// add sort by iou if configured on item
		if(this.results?.[0].averageIoU){
			list.addAttribute("Average IoU", item=>item.averageIoU);
			for(let [key, clazz] of Object.entries(classmap)){
				list.addAttribute(`Average ${clazz} IoU`, item=>item.averageClassIoU[key]);
			}
		}

		await list.sort("UUID", true);
		list.filter(filterFunction);
		this.append(list);

		// wire the filter to update whenever the form changes
		filterForm.addEventListener('change', ()=>{
			list.filter(filterFunction);
		});
	}

}

window.customElements.define("ui-evaluationclassresults", EvaluationClassResults);