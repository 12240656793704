import * as UI from "automaton-ui";
import API from "/scripts/API.js";
import TrainingPlot from "../models/TrainingPlot.js";

import {bytesToHumanReadable} from "/scripts/Utils.js";

/**
 * Detail panel for an item
 */
class JobPanel extends UI.BasicElement{

	constructor(uuid){
		super(``);
		this._ready = false;
		this.uuid = uuid;
		this.json = null;
		this.init();
	}

	async init(){
		let json = await API.job(this.uuid).get();
		this.json = json;

		console.log(json);

		this.append(new UI.Json(json));

		//
		if(json.files){
			this.append(UI.utils.htmlToElement(`<h3>Output files</h3>`));
			for(let f of Object.keys(json.files)){
				let button = new UI.Button(`${f} (${bytesToHumanReadable(json.files[f].length)})`, ()=>{
					API.download(this.uuid, f);
				});
				this.append(button);
			}
		}

		// plot
		if(json.type == "Model"){
			this.append(new TrainingPlot(await API.model(json.model).get()));
		}

		// raw logs
		if(json.files["logs.txt"]){
			let resp = await API.file(this.uuid, "logs.txt").get();
			let logs = await resp.text();
			this.logsPanel = new UI.Code(logs);
			this.append(this.logsPanel);
			this.setInterval(this.update.bind(this), 60_000);
		}

		this._ready = true;
	}

	async ready(){
		while(!this._ready) {
			await UI.utils.sleep(10);
		}
	}

	async update(){
		this.json = await API.job(this.uuid).get();

		this.logsPanel.setContent(this.json.logs);
	}

}

window.customElements.define("ui-job", JobPanel);
export default JobPanel;