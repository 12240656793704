import * as UI from "automaton-ui";
import API from "/scripts/API.js";
import TagSelect from '/component/models/TagSelect.js';


const FORM_TEMPLATE = [
	{
		key: "name",
		type: "string"
	},
	{
		key: "tags",
		name: "Tags",
		type: TagSelect
	}
];


/**
 * Detail panel for an item
 */
class ModelEditor extends UI.Modal{

	constructor(uuid, possibleTags){
		super(``, {buttons: `<ui-cancel></ui-cancel><ui-spacer></ui-spacer>`});

		this.uuid = uuid;
		this.possibleTags = possibleTags;
		this.init();
		this._ready = false;
	}

	async init(){
		let json = await this.getFormJson();

		// create form
		let form = new UI.Form(FORM_TEMPLATE);
		form.build(json);
		this.panel.append(form);

		// configure update button
		this.panel.footer(new UI.Button(
			"Update",
			async ()=>{
				let json = form.json();

				// transform 'tags' key to format expected by API
				json.tags = json.tags.selected;

				// push changes and reload page
				await API.model(this.uuid).update(json);
				location.reload();
			}
		));

		this._ready = true;
	}

	async getFormJson(){
		let json = await API.model(this.uuid).get();

		// transform 'tags' key to provide the data needed by the tags UI tool
		json.tags = {
			'possible': this.possibleTags,
			'selected': json.tags ?? []
		};

		return json;
	}

	async ready(){
		while(!this._ready) {
			await UI.utils.sleep(10);
		}
	}

}

window.customElements.define("ui-model-editor", ModelEditor);
export default ModelEditor;