import { TASK_TEMPLATE } from "/component/task/Task.js";
import * as UI from "automaton-ui";
import API from "/scripts/API.js";


async function NewTask(){
	return new Promise(resolve=>{
		//build the form
		let form = new UI.Form(TASK_TEMPLATE);
		// init with data
		form.build({
			type: "Task"
		});
		// push it into a modal
		let modal = new UI.Modal(form, {
			title: "New Task",
			buttons: "<ui-cancel></ui-cancel><ui-spacer></ui-spacer><ui-button icon='fa-check'>Create</ui-button>",
			dismissable: false
		}).attach();

		modal['close'] = ()=>{
			resolve(null);
			modal.self.remove();
			return modal;
		};

		modal.querySelector('ui-button[icon="fa-check"]').addEventListener("click", async ()=>{
			let json = form.json();
			let task = await API.entity({type:"task", data:json});
			resolve(task);
			modal.self.remove();
		});
	});
}
export default NewTask;
