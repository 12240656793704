import * as UI from "automaton-ui";
import API from "/scripts/API.js";

const COLLECTION_TEMPLATE = [
	{
		key: "type",
		type: "list",
		options: ()=>{
			return ["Collection"];
		}
	},
	{
		key :"data.name",
		type: "string",
		name: "name"
	},
	{
		key :"data.description",
		type: "text",
		name: "description"
	}
];

async function NewCollection(){
	return new Promise(resolve=>{
		//build the form
		let form = new UI.Form(COLLECTION_TEMPLATE);
		// init with data
		form.build({
			type: "Collection"
		});
		// push it into a modal
		let modal = new UI.Modal(form, {
			title: "New Collection",
			buttons: "<ui-cancel></ui-cancel><ui-spacer></ui-spacer><ui-button icon='fa-check'>Create</ui-button>",
			dismissable: false
		}).attach();

		modal['close'] = ()=>{
			resolve(null);
			modal.self.remove();
			return modal;
		};

		modal.querySelector('ui-button[icon="fa-check"]').addEventListener("click", async ()=>{
			let collection = await API.entity(form.json());

			resolve(collection);
			modal.self.remove();
		});
	});
}
export default NewCollection;