import * as UI from 'automaton-ui';

class AuthPanel extends UI.BasicElement{

	/** @type {AuthPanel} */
	static INSTANCE;

	username;

	static username = ()=> AuthPanel.INSTANCE.username;

	constructor(){
		super();

		AuthPanel.INSTANCE = this;

		try{
			// @ts-ignore
			this.msal = new Msal.UserAgentApplication({
				auth: {
					clientId: '893f3fd8-db33-46cb-96ff-599a5b26bc38',
					authority: 'https://login.microsoftonline.com/86f19289-d141-4793-baf0-e573a3189af2'
				},
				cache: {
					cacheLocation: 'localStorage',
					storeAuthStateInCookie: true
				}
			});

			this.request = {
				scopes: ['user.read']
			};

			this.passive();
		}catch(e){
			console.warn(e);
			//location.reload();
		}

		//setup the login modal
		this.modal = new UI.Modal(`
				<p>This area requires a snaptech.ai login to continue</p>
				<ui-button icon="fa-windows">Sign in with Microsoft</ui-button>`,
		{
			title: "Auth required",
			dismissable: false
		});
		this.modal.querySelector('ui-button').addEventListener('click', ()=>this.signIn());

		// make sure the auth modal is infront of the busy splash!
		this.modal.style.zIndex = "10000";

		//
		this.innerHTML = `...`;

		this.onclick = ()=>this.signOut();
	}

	async onLogin(account){
		this.modal.hide();
		this.username = account.userName;
		this.innerHTML = `<ui-badge icon="fa-user">${account.userName}</ui-badge>`;

		try{
			await this.configurePermissionAttributes();
		}catch(e){
			// the user authenticated, but doesn't have any permissions :'(
			new UI.Modal(
				"In order to use this site your snap account has to have permissions attached.<br/> Contact a developer to get the correct permissions setup",
				{"title": "Permissions required!"}
			).show();
		}
	}

	async configurePermissionAttributes(){
		// get user profile
		let profile = await (await this.fetch('/api/user', {})).json();

		// clear existing attributes
		for(let attr of document.body.attributes){
			document.body.removeAttribute(attr.name);
		}

		// add permission attributes
		for(let p of profile.permissions){
			document.body.setAttribute(p.toLowerCase(), '');
		}
	}

	async fetch(url, options = {}){
		// wait for a token
		while(this._token == null) {
			await new Promise(res=>setTimeout(res,100));
		}

		// append auth info
		if(options.headers == null) {
			options.headers = {};
		}
		options.headers.Authorization = 'Bearer ' + this._token;

		// return the fetch
		return await fetch(url, options);
	}

	async getAuthToken(){
		// attempt to passively gain an auth token
		await this.passive();
		// if we failed to get a token then the user must need to login
		// wait for the user to sign in!
		while(this._token==null) {
			await UI.utils.sleep(100);
		}
		// finally we can return the now valid token
		return this._token;
	}

	async passive(){
		try{
			let oldToken = this._token;
			this._token = null;
			// try to passively fetch the auth token (from stored info)
			this._token = await new Promise((res)=>{
				this.msal.acquireTokenSilent(this.request)
					.then((data)=>{
						let token = data.accessToken;
						res(token);
					})
					.catch((_e)=>{
						console.warn(_e);
						this.renderLogin();
					});
			});

			// if our token changed update our display
			if(this._token!=oldToken) {
				await this.onLogin(this.msal.account);
			}
		}catch(e){
			console.warn(e);
			// nope; will need the user to sign in
			this.renderLogin();
		}
	}

	async renderLogin(){
		this.modal.show();
	}

	signIn(){
		this.msal.loginPopup(this.request).then((_loginResponse)=>{
			this.passive();
		}).catch(function (error) {
			console.log(error);
		});
	}

	signOut(){
		this.username = null;
		this.msal.logout();
		location.reload();
	}

}
window.customElements.define("ui-auth", AuthPanel);
export default AuthPanel;
