import UI from "automaton-ui";
import API from "/scripts/API.js";

import NewCollection from "/component/NewCollection.js";

import WorldConfig from "/component/world/WorldConfig.js";
import * as Export from "/component/world/WorldDatasetExport.js";
import WorldFilter from "/component/global/WorldFilter.js";
import * as Utils from "/scripts/Utils.js";

/**
 * Detail panel for a World
 */
class WorldPanel extends UI.BasicElement{

	constructor(uuid){
		super();

		this.uuid = uuid;

		// bind functions so they can be called without wrapping this context
		this.configure = this.configure.bind(this);
		this.explore = this.explore.bind(this);

		this.init();
	}

	async init(){
		WorldFilter.setWorld(this.uuid);

		let world = await API.world(this.uuid).get();
		world.exports = world.exports || [];
		world.collections = world.collections || [];
		// setup main section

		let main = new UI.Panel("", {header:true,footer:false});
		main.header(`'${world.name}' world`,
			new UI.Spacer(),
			new UI.Button('', this.explore, {icon: 'fa-eye', style: 'text'}),
			new UI.Button('', this.configure ,{icon: 'fa-cog', style: 'text'})
		);
		main.append(UI.utils.htmlToElement(`<p>${world.description}</p>`));

		// load collection list
		let collections = (await API.collection().list());
		collections = collections.filter(c=>c && world.collections.includes(c.uuid));

		// allow delete if no attached collections AND no datasets
		if(collections.length == 0 && (await API.dataset().list()).filter(d=>d.world == this.uuid).length == 0){
			main.append(new UI.Button("Delete", async ()=>{
				if(confirm("Really Delete World?")){
					await API.world(this.uuid).delete();
					UI.info("World Deleted");
					// back to world list page
					location.href = '#world';
				}
			}, {icon: 'fa-trash', color: 'delete'}));
		}

		let menu = new UI.ContextMenu();
		menu.addItem("Configure", this.configure);
		menu.addItem("View Json", ()=>new UI.Modal(new UI.Json(world)).show());
		menu.for(main);

		this.append(main);

		// setup collections section
		let collectionPanel = new UI.Panel("", {title: "Collections", buttons: "<ui-spacer></ui-spacer>", header:true,footer:true});

		let tasks = await API.task().list();

		let collectionTable = new UI.Table({});
		collectionTable
			.addAttribute("Name","name")
			.addAttribute("Items", (c)=>c.items.length)
			.addAttribute("Tasks", (c)=>(c.tasks || []).map(uuid=>{
				let badge = new UI.Badge(tasks.find(t=>t.uuid==uuid).name);
				badge.addEventListener("click", ()=>location.hash=`task/${uuid}`);
				badge.style.cursor = "pointer";
				return badge;
			}))
			.addAttribute("Actions", null, (c)=>[
				new UI.Button("View", Utils.getUrl('collection', c.uuid))
			]);
		collectionTable.data = collections;
		collectionTable.render();
		collectionPanel.append(collectionTable);

		collectionPanel.footer(new UI.Button("New Collection...", async ()=>{
			let collection = await NewCollection();
			if(collection){
				// add the created collection to the world
				world.collections.push(collection.uuid);
				await API.world(this.uuid).update(world);

				// flow to the collection
				window.location.hash = `collection/${collection.uuid}`;
			}
		}));
		this.append(collectionPanel);

		// setup exports section

		let exportsPanel = new UI.Panel("", {title: "Dataset Export Configuration", buttons: '<ui-spacer></ui-spacer>'});
		let exportTable = new UI.Table({itemsPerPage: 120});
		exportTable
			.addAttribute("Name","name")
			.addAttribute("Export Type",(e)=>Export.EXPORT_TYPE_DISPLAY[e.type])
			.addAttribute("Classes",(e)=>e.classes?.map(c=>new UI.Badge(c)))
			.addAttribute("Actions", null, (exportConfig)=>[
				new UI.Button("Configure", ()=>this.configureExport(world, exportConfig), {icon: "fa-cog"}),
				new UI.Button("Download Export", ()=>this.export(exportConfig), {icon: "fa-file-download", style: "success"}),
				new UI.Button("Generate Dataset", ()=>this.generateDataset(exportConfig), {icon: "fa-notes-medical", style: "success"})
			]);
		exportTable.data = world.exports;
		exportTable.renderItem = async (item)=>{
			let row = await UI.Table.prototype.renderItem.call(exportTable, item);

			new UI.ContextMenu()
				.addItem("Clone", ()=>{
					// clone the config
					let config = JSON.parse(JSON.stringify(item));
					// edit the name
					config.name = "Clone of " + config.name;
					// open the editor for this item
					this.configureExport(world, config, true);
				})
				.addItem("Delete", ()=>{
					if(!confirm("Really delete export configuration?")) {
						return;
					}
					// remove this item
					world.exports = world.exports.filter(e=>e!=item);

					// save
					this.save(world);
				})
				.for(row);
			return row;
		};
		exportTable.render();
		exportsPanel.append(exportTable);

		// SETUP export modal creator
		exportsPanel.footer(new UI.Button("New Export Configuration...", async ()=>{
			this.configureExport(world, null);
		}));

		if(world.collections.length > 0) {
			this.append(exportsPanel);
		}
	}

	async configureExport(world, exportConfig, forceCreate = false){
		let create = forceCreate || exportConfig == null;
		exportConfig = exportConfig || {rules:[]};

		let modal = new UI.Modal("", {title: "Configure Export", buttons: "<ui-cancel></ui-cancel><ui-spacer></ui-spacer>"});

		let form = await Export.exportConfigForm(world, exportConfig);

		// grab the first button (EXPORTED CLASSES add) and add
		let attachAddFromTaskButton = ()=>{
			let addClassButton = form.querySelector('[data-element="classes"] > td > [ui-button]');
			addClassButton.insertAdjacentElement('afterend',
				new UI.Button("Add From Task", async ()=>{
					// all the collections attached to this world
					let worldCollections = (await API.collection().list())
						.filter(c=>c && world.collections.includes(c.uuid));

					// all the tasks attached to a collection in this world
					let worldTasks = (await API.task().list())
						.sort(Utils.sortByName)
						.filter(task => task.type == "bucket" || task.type == "multi-class-box" || task.type == "multi-class-polygon")
						.filter(task => worldCollections.find(c=>c.tasks.includes(task.uuid))!=null);

					// pick a task form
					let taskPicker = await new UI.Form([{
						key: "task",
						type: "dropdown",
						options: worldTasks.map(task=>{
							return {value: task.uuid, name: task.name};
						})
					}]).build({});

					// show modal
					let modal = new UI.Modal(taskPicker, {buttons: '<ui-cancel></ui-cancel><ui-spacer></ui-spacer>'}).show();
					modal.panel.footer(new UI.Button("Select", async ()=>{
						// get the task that was selected
						let taskUUID = taskPicker.json().task;
						let task = worldTasks.find(task=>task.uuid == taskUUID);
						// update the config with the tasks classes
						let json = form.json();
						// append the classes
						if(json.classes){
							json.classes.push(...task.buckets.map(b=>b.key));
							// dedupe classes
							json.classes = [...new Set(json.classes)];
						}else{
							json.classes = task.buckets.map(b=>b.key);
						}
						// redraw the form
						form.innerHTML = '';
						await form.build(json);
						// re-add this button
						attachAddFromTaskButton();
						// hide this modal
						modal.hide();
					}));
				}));
		};
		attachAddFromTaskButton();

		modal.firstElementChild.append(form);

		// @ts-ignore
		modal.firstElementChild.footer(new UI.Button(create?"Create":"Update", async ()=>{
			let newExportConfig = form.json();

			if(newExportConfig.classes==null || newExportConfig.classes.length==0){
				new UI.Toast("Export Classes are required!", {level: 'warn'});
				return;
			}

			if(create){
				// add the config to the world
				world.exports.push(newExportConfig);
			}else{
				// update the existing config
				// first clear the original object
				for (const prop of Object.getOwnPropertyNames(exportConfig)) {
					delete exportConfig[prop];
				}
				// then copy on my new values
				Object.assign(exportConfig, newExportConfig);
			}
			// now we can save our (up to date) world config;
			this.save(world);
		}));

		modal.show();
	}

	async save(world){
		// now we can save our (up to date) world config;
		await API.world(this.uuid).update(world);

		// reload the page (we could redraw but this is easier)
		location.reload();
	}

	async configure(){
		new UI.Modal(new WorldConfig(this.uuid)).show();
	}

	explore(){
		window.location.hash = Utils.getUrl('world/viewer', this.uuid);
	}

	/**
	 *
	 * @param {*} exportConfig
	 */
	async generateExportJson(exportConfig){
		return await API.world(this.uuid).export(exportConfig.name);
	}

	/**
	 *
	 * @param {*} exportConfig
	 */
	async generateDataset(exportConfig){
		try {
			let result = await API.world(this.uuid).createDataset(exportConfig.name);
			if(result == null){
				return;
			}
			location.href = `#dataset`;
		} catch (e) {
			alert(e);
		}
	}

	/**
	 *
	 * @param {*} exportConfig
	 */
	async export(exportConfig){
		let json = await this.generateExportJson(exportConfig);
		if(json == null){
			return;
		}

		// We can now download the file
		downloadJson(exportConfig.name + ".json", json);
	}

}

window.customElements.define("ui-world", WorldPanel);
export default WorldPanel;

/****/

function downloadJson(filename, json){
	const a = document.createElement('a');
	a.href = URL.createObjectURL( new Blob([JSON.stringify(json, null, '\t')], { type:`text/json` }) );
	a.download = filename;
	a.click();
}
