import * as UI from 'automaton-ui';
import API from '/scripts/API.js';

class WorldFilter extends UI.BasicElement{

	/** the UUID of the world we wish to filter to */
	uuid = "NONE";

	// cache of worlds available
	worlds;

	// currently selected world
	world = null;

	// array of valid datasets for the world
	/** @type {String[]} */
	filteredDatasetUUIDs = [];

	// array of valid tasks for the world
	/** @type {String[]} */
	filteredTaskUUIDs = [];

	// the active list that we should filter for
	/** @type {UI.List} */
	list = null;

	#ready = false;

	constructor(){
		super();

		this.filter = this.filter.bind(this);

		this.update();
	}

	async ready(){
		while(!this.#ready) {
			await UI.utils.sleep(10);
		}
	}

	reset(){
		this.setWorld(null);
	}

	async update(){
		let previousUUID = this.uuid;

		// update our internal state
		await this.syncroniseState();

		// if changed - update our picker
		if(previousUUID!=this.uuid){
			this.drawPicker();
		}

		// filter any attached list
		if(this.list){
			this.list.dirty = true;
			this.list.render();
		}

		// update the header links
		let param = this.isEnabled()?"|"+this.getHashParam():"";
		document.querySelectorAll('body > header > nav > a')
			.forEach(a=>a['href'] = a['href'].split("|world")[0] + param);

		this.#ready = true;
	}

	/**
	 * Syncronise internal state with the location hash
	 *
	 */
	async syncroniseState(){
		// read the current location hash state
		this.uuid = UI.HashManager.read("world");

		while(!API)
			await sleep(250);

		// get all the available worlds for render/filtering
		this.worlds = (await API.world().list()).sort((a,b)=>a.name.localeCompare(b.name));

		// set our internal state
		if(this.uuid){
			// load some data
			let datasets = await API.dataset().list();
			let collections = await API.collection().list();

			this.world = this.worlds.find(w=>w.uuid == this.uuid);

			// get datasets belonging to world
			this.filteredDatasetUUIDs = datasets
				.filter(this.filter)
				.map(d=>d.uuid);

			// get tasks belonging to world
			this.filteredTaskUUIDs = collections
				.filter(this.filter)
				.flatMap(c=>c.tasks);
		}else{
			this.world = null;
		}
	}

	async setWorld(uuid){
		// push the new uuid onto the location hash
		UI.HashManager.write("world", uuid);

		// update ourself from that hash
		await this.update();

		this.dispatchEvent(new Event('change'));
	}

	drawPicker(){
		this.innerHTML = "";
		let picker = document.createElement('select');
		picker.addEventListener('change', ()=>{
			let value = picker.value;
			if (value == 'null'){
				value = null;
			}
			this.setWorld(value);
		});

		let options = [
			{value: null, display:"All Worlds"}
		];
		for(let world of this.worlds){
			options.push({value: world.uuid, display: world.name});
		}

		for(let option of options){
			let opt = document.createElement('option');
			opt.value = option.value;
			opt.innerHTML = option.display;
			if(this.uuid == option.value){
				opt.selected = true;
			}
			picker.append(opt);
		}

		this.append(picker);
	}

	/**
	 *
	 * Sets this filter to filter the list provided
	 *
	 * @param {UI.List} list
	 */
	async apply(list){
		// force a rebuild for our current state - the list navigation probably killed it
		await this.setWorld(this.uuid);

		if(list._filterFunc){
			// if the filter already has a filter then logical and the filters
			let baseFilter = list._filterFunc;
			list._filterFunc = (item)=>{
				return this.filter(item) && baseFilter(item);
			};
		}else{
			// otherwise just attach this filter directly
			list._filterFunc = this.filter;
		}
		// we only need to hold one list as there is only ever one top level
		// list visible on the page
		this.list = list;
	}

	filter(entity){
		if(!this.uuid) {
			return true;
		}
		// dataset has a direct reference to the world
		if(entity.world == this.uuid) {
			return true;
		}
		// show a collection in this world
		if(this.world?.collections?.includes(entity.uuid)) {
			return true;
		}
		// models/job referring to a (valid) dataset
		if(this.filteredDatasetUUIDs.includes(entity.dataset)) {
			return true;
		}
		// or a task uuid in our pre-filtered list
		if(this.filteredTaskUUIDs.includes(entity.uuid)) {
			return true;
		}
		return false;
	}

	isEnabled(){
		return !!this.uuid;
	}

	getHashParam(){
		return "world=" + this.uuid;
	}

}
window.customElements.define("ui-world-filter", WorldFilter);
let filter = new WorldFilter();
export default filter;
