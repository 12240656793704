import * as UI from 'automaton-ui';
import API from '/scripts/API.js';
import NewCollection from "/component/NewCollection.js";
import WorldFilter from "/component/global/WorldFilter.js";
import * as Utils from "/scripts/Utils.js";

export class CollectionList extends UI.BasicElement{

	constructor(){
		super();

		this.setAttribute('landing-list', '');

		this.init();
	}

	async init(){
		let json = await API.collection().list();

		let list = new UI.List(collection => {
			let panel = new UI.Panel(collection.items.length?`<img class="preview" src="image/${collection.items[0]}"/>`:'',
				{title: collection.name, buttons: `${collection.items.length} items`, clazz: 'clickable'});
			let url = Utils.getUrl('collection', collection.uuid);
			panel.onclick = ()=>window.location.hash = url;

			let menu = new UI.ContextMenu();
			menu.addItem("Delete", async ()=>{
				await API.collection(collection.uuid).delete();
				new UI.Toast("Collection deleted");
				panel.remove();
			});
			menu.for(panel);

			return panel;
		}, {
			itemsPerPage: 120
		});

		await WorldFilter.apply(list);

		list.addAttribute("Name", "name");
		list.addAttribute("Created", "created");

		list.sort("Created", true);

		list.data = json;
		list.render();

		this.append(list);

		let button = new UI.Button("New Collection...", async ()=>{
			let collection = await NewCollection();
			if(collection) {
				window.location.hash = `collection/${collection.uuid}`;
			}
		}, {style: "new-button"});
		this.append(button);
	}

}
window.customElements.define("ui-collectionlist", CollectionList);
export default CollectionList;