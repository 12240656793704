import WorldFilter from '/component/global/WorldFilter.js';

/**
 * returns true if array a contains at least one member of array b
 *
 * @param {*} a
 * @param {*} b
 */
export function intersects(a,b){
	for(let va of a){
		if(b.includes(va)) {
			return true;
		}
	}
	return false;
}

export function randomItem(array){
	return array[Math.floor(Math.random()*array.length)];
}

export function bytesToHumanReadable(number){
	if(number < 1024) {
		return number + "bytes";
	}
	number/=1024;
	if(number < 1024) {
		return number.toFixed(1) + "KB";
	}
	number/=1024;
	if(number < 1024) {
		return number.toFixed(1) + "MB";
	}
	number/=1024;
	return number.toFixed(1) + "GB";
}

export function getUrl(entity, uuid, path=null){
	let url = `#${entity}${uuid?`/${uuid}`:``}`;
	if(path){
		url = url.concat(`/${path}`);
	}
	if(WorldFilter.isEnabled()){
		url = url.concat(`|${WorldFilter.getHashParam()}`);
	}
	return url;
}

export function sortByName(a,b){
	return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
}