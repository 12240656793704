import * as UI from "automaton-ui";
import API from "/scripts/API.js";
import ItemPanel from "/component/item/ItemPanel.js";

/**
 * View all items in a world
 */
class WorldViewer extends UI.BasicElement{

	ANY_CLASS = "-";

	constructor(uuid){
		super();
		this.uuid = uuid;
		this.init();
	}

	async init(){
		let items = await API.world(this.uuid).items();

		// add panel
		let itemsPanel = new UI.Panel('', {title: `${items.length} Items`});
		this.append(itemsPanel);

		// exit early if there are no items
		if(items.length == 0){
			return;
		}

		// configure filter
		let bucketTasks = (await this.getTasks()).filter(task=>task.type === 'bucket');
		let filterForm = await this.createFilterForm(bucketTasks);
		if(bucketTasks.length !== 0){
			// add filter to UI
			let filterElement = UI.utils.htmlToElement("<header class='filter'></header>");
			filterElement.append(filterForm);
			itemsPanel.header(filterElement);
		}
		let filterFunction = this.createFilterFunction(filterForm);

		// configure item list
		let itemList = this.createItemViewer(items, filterFunction);
		itemsPanel.append(itemList);

		// wire the filter to update whenever the form changes
		filterForm.addEventListener('change', ()=>{
			itemList.filter(filterFunction);
		});

		// sort list by created
		await itemList.sort("Created", true);
	}

	async getTasks(){
		let world = await API.world(this.uuid).get();
		let collectionsTaskUUIDS = (await API.collection().list())
			.filter(collection=>collection && world.collections?.includes(collection.uuid))
			.map(collection=>collection.tasks)
			.flat();

		return (await API.task().list())
			.filter(task=>collectionsTaskUUIDS?.includes(task.uuid) ?? false)
			.sort((a,b)=>a.name.localeCompare(b.name));
	}

	createItemViewer(items, filterFunction){
		let itemList = new UI.List(item=>{
			return new ItemPanel(item.uuid, {annotations: true, json: item});
		}, {itemColumns: 4});

		// odd value function as sorting base16 numbers isn't something UI.List handles well!
		itemList.addAttribute("UUID", item=>parseInt(item.uuid.split('-')[0],16));
		itemList.addAttribute("Created", "created");
		itemList.addAttribute("Image Size", (item)=> item.image.width * item.image.height);

		itemList.data = items;
		itemList._filterFunc = filterFunction;

		return itemList;
	}

	async createFilterForm(tasks){
		// build data
		let formData = [];
		let data = [];
		for(let task of tasks){
			let options = task.buckets.map(b=>{
				return {
					name:b.key,
					value:b.key,
				};
			});
			options.unshift({
				name: 'Any label',
				value: this.ANY_CLASS
			});
			formData.push({
				key: task.uuid,
				name: `<span>${task.name}</span>`,
				type: 'list',
				format: 'string',
				options: ()=>options
			});

			data[task.name] = this.ANY_CLASS;
		}

		// create form
		let filterForm = new UI.Form(formData);
		filterForm.formStyle = UI.Form.STYLE.INLINE;
		await filterForm.build(data);

		return filterForm;
	}

	createFilterFunction(filterForm){
		return (item)=>{
			let filterObject = filterForm;

			for(let key of Object.keys(filterObject.value)){
				let value = filterObject.value[key];
				if(value === this.ANY_CLASS){
					continue;
				}

				let label = item.labels
					?.filter(l=>l.task === key)
					.shift()
					?.data?.labels?.bucket;

				if(label !== value){
					return false;
				}
			}

			return true;
		};
	}

}

window.customElements.define("ui-worldviewer", WorldViewer);
export default WorldViewer;
