import UI from "automaton-ui";
import TaskList from "/component/task/TaskList.js";
import API from "/scripts/API.js";
import * as Utils from "/scripts/Utils.js";

/**
 * Detail panel for an item
 */
class ItemPanel extends UI.BasicElement{

	constructor(uuid, {annotations = false, json = null, controls = false, tasks = null}={}){
		super(`<content><img src="/image/${uuid}"/></content>`);
		this._ready = false;

		this.uuid = uuid;

		this.json = json;

		this.controls = controls;

		this.tasks = tasks;

		if(this.controls){
			this.classList.add('extended');
		}

		this.annotations = annotations;

		this.menu = new UI.ContextMenu();
		this.menu.for(this.querySelector('content'));

		this.init();
	}

	/**
	 *
	 * Find the label on this item for the task uuid provided (or null)
	 *
	 * @param {String} task
	 *
	 * @returns {Promise<*>}
	 */
	async getLabel(task){
		await this.ready();
		return this.json.labels.find(label=>label.task==task);
	}

	instanceColor(index){
		return `hsl(${index*80}, 100%, 50%)`;
	}

	isLikleySnapId(string){
		if(isNaN(parseInt(string))) {
			return false;
		}
		if(string.startsWith('0')) {
			return false;
		}
		let id = parseInt(string);
		// we're currently at 74 million ish.
		if(id > 1_000_000_000){
			return false;
		}
		return true;
	}

	async init(){
		if(this.json == null){
			let json = await API.item(this.uuid).info();
			this.json = json;
		}
		this.json.labels = this.json.labels ?? [];

		let size = document.createElement("span");
		size.classList.add("image-size");
		size.innerHTML = `${this.width} x ${this.height}`;
		this.addLabel(size);

		// create right click menu
		if(!location.hash.startsWith('#item')){
			this.menu.addItem("<i class='fa fa-external-link-alt'></i> Open Item Panel", ()=>{
				window.open(Utils.getUrl('item', this.uuid));
			});
		}

		let buckets = null;
		let detailsContainer = null;
		if(this.controls){
			this.tasks = this.tasks ?? await API.task().list();

			let controls = UI.utils.htmlToElement("<div class='controls'></div>");
			// generate a more informative info table
			controls.innerHTML = `<table class="table">
				<tr><th>UUID</th><td>${this.json.uuid}</td></tr>
				<tr><th>Collection</th><td><a href="${Utils.getUrl('collection', this.uuid)}">${this.json.collection}</a></td></tr>
				<tr><th>Source ID</th><td>${this.json.sourceId}</td></tr>
				<tr><th>Created</th><td>${this.json.created}</td></tr>
				<tr><th>Annotations</th><td class="tags"></td></tr>
				<tr><th>Product Details</th><td class="details"></td></tr>
			</table>
			<hr/>
			<div class="buttons"></div>`;
			this.append(controls);

			buckets = controls.querySelector('.tags');
			detailsContainer = controls.querySelector('.details');

			let buttons = controls.querySelector('.buttons');
			if(this.json.collection){
				buttons.append(new UI.Button("Delete", async ()=>{
					if(!confirm("Really delete this item and all annotations linked to it? This action cannot be undone!")) {
						return;
					}
					await API.collection(this.json.collection).remove(this.json.uuid);
					UI.info("Deleted!");
					let modal = (this.closest('ui-modal'));
					if(modal){
						// @ts-ignore
						modal.close();
						// reload as the page has the invalid item on!
						location.reload();
					}else{
						// not in a modal, go back once
						if(window.history.length>1){
							window.history.back();
						}else{
							// must be a popup; try to close the window
							window.close();
						}
					}
				}, {icon: 'fa-trash', color: 'delete'}));
			}
		}else{
			let uuid = document.createElement("span");
			uuid.classList.add("image-uuid");
			uuid.innerHTML = `${this.uuid} <span>(${this.json['sourceId']})</span>`;
			this.append(uuid);

			buckets = UI.utils.htmlToElement("<div class='tags'></div>");
			this.append(buckets);
		}

		// RENDER labels
		if(this.annotations){
			let baseHue = 0;
			for(let annotation of this.json.labels){
				if(this.annotations != true && annotation.task != this.annotations) {
					continue;
				}

				let task = null;
				let bucketTarget = buckets;
				if(this.tasks){
					task = this.tasks.find(t=>t.uuid == annotation.task);
					let bucketTarget = UI.html(`<div><b>${task.name}</b></div>`);
					buckets.append(bucketTarget);
				}


				let labels = [];
				// render buckets
				if(annotation.data.labels?.bucket){
					if(Array.isArray(annotation.data.labels.bucket)){
						let hue = baseHue;
						for(let bucket of annotation.data.labels.bucket){
							let badge = new UI.Badge(bucket);
							badge.setCss('border', `1px solid hsl(${hue}, 100%, 50%)`);
							bucketTarget.append(badge);
							hue += 70;
							labels.push(badge);
						}

						// edit polygon of the item
						if(task?.type == "multi-class-polygon"){
							bucketTarget.append(new UI.Button("", Utils.getUrl('polygon', this.json.collection, `${task.uuid}|item=${this.uuid}|next=ItemPanel`), {icon: 'fa-edit', style: 'text'}));
						}

						// edit boxes
						if(task?.type == "multi-class-box"){
							bucketTarget.append(new UI.Button("", Utils.getUrl('box', this.json.collection, `${task.uuid}|item=${this.uuid}|next=ItemPanel`), {icon: 'fa-edit', style: 'text'}));
						}
					}else{
						let badge = new UI.Badge(annotation.data.labels.bucket);
						bucketTarget.append(badge);

						// offer in-panel corrections
						if(task?.type == "bucket"){
							bucketTarget.append(new UI.Button("", async ()=>{
								let v = await UI.factory.popupForm({
									key: 'bucket',
									type: 'string',
									options: task.buckets.map(b=>b.key),
									default: annotation.data.labels.bucket
								}, {title: task.name});
								if(v?.bucket){
									await API.item(this.uuid).task(task.uuid).label.create({
										bucket: v.bucket
									});
									badge.innerHTML = v.bucket;
									UI.info("Updated!");
								}
							}, {icon: 'fa-edit', style: 'text'}));
						}
					}
				} else {
					// show "instance" badge
					let badge = new UI.Badge("instance");
					bucketTarget.append(badge);
					labels.push(badge);

					// edit polygon of the item
					if(task?.type == "polygon"){
						bucketTarget.append(new UI.Button("", Utils.getUrl('polygon', this.json.collection, `${task.uuid}|item=${this.uuid}|next=ItemPanel`), {icon: 'fa-edit', style: 'text'}));
					}

					// edit boxes
					if(task?.type == "box"){
						bucketTarget.append(new UI.Button("", Utils.getUrl('box', this.json.collection, `${task.uuid}|item=${this.uuid}|next=ItemPanel`), {icon: 'fa-edit', style: 'text'}));
					}
				}

				// render the boxes
				if(annotation.data.labels?.box){
					let hue = baseHue;
					for(let box of annotation.data.labels.box){
						let div = document.createElement("div");
						div.classList.add('bounding-box','overlay');
						div.style.position = "absolute";
						div.style.left = (100*box[0]/this.width) + "%";
						div.style.top = (100*box[1]/this.height) + "%";
						div.style.width = (100*box[2]/this.width) + "%";
						div.style.height = (100*box[3]/this.height) + "%";
						div.style.outline = `2px dashed hsl(${hue}, 100%, 50%)`;
						this.addLabel(div);

						if(labels.length){
							let label = labels.shift();
							label.addEventListener('mouseenter', ()=>{
								this.querySelectorAll('.overlay').forEach(e=>{
									e.classList.remove('highlight'); e.classList.add('fade');
								});
								div.classList.add('highlight');
							});
							label.addEventListener('mouseleave', ()=>{
								this.querySelectorAll('.overlay').forEach(e=>{
									e.classList.remove('highlight'); e.classList.remove('fade');
								});
							});
						}

						hue += 70;
					}
				}

				// render polygons
				if(annotation.data.labels?.polygon){
					let hue = baseHue;
					for(let instance of annotation.data.labels.polygon){
						let borderCanvas = this.renderPolygonsToCanvas(instance, hue);
						this.addLabel(borderCanvas);

						let fillCanvas = this.renderPolygonsToCanvas(instance, hue, 0, true);
						fillCanvas.classList.add('hide');
						this.addLabel(fillCanvas);

						if(labels.length){
							let label = labels.shift();
							label.addEventListener('mouseenter', ()=>{
								this.querySelectorAll('.overlay').forEach(e=>{
									e.classList.remove('highlight'); e.classList.add('fade');
								});
								borderCanvas.classList.add('highlight');
								fillCanvas.classList.remove('fade', 'hide');
							});
							label.addEventListener('mouseleave', ()=>{
								this.querySelectorAll('.overlay').forEach(e=>{
									e.classList.remove('highlight'); e.classList.remove('fade');
								});
								fillCanvas.classList.add('hide');
							});
						}
						hue += 70;
					}
				}

				baseHue += 29; // we want a different set of non-overlapping colours for the next labels
			}
		}

		// add snap specific info
		if(this.isLikleySnapId(this.json['sourceId'])){
			// add link to snap-control
			this.menu.addItem("<i class='fa fa-external-link-alt'></i> View in control", ()=>
				window.open(`https://control.snap.vision/product?id=${this.json['sourceId']}`)
			);

			if(detailsContainer){
				// show product details
				let details = await await API.item(this.uuid).snapDetails();
				for(const [key, value] of Object.entries(details)){
					detailsContainer.append(UI.html(`<div><b>${key}</b>: ${value}</div>`));
				}
			}
		}

		// Add tasks context menu items
		if(this.tasks && this.annotations){
			// neighbour links
			let neighbourTasks = this.tasks.filter((t)=>t.type=="feature" && this.json.labels.find(a=>a.task==t.uuid));
			if(neighbourTasks.length > 0){
				// neighbours
				let sub = this.menu.addSubMenu(`Neighbours`);
				for(let task of neighbourTasks){
					sub
						.addItem(`<i class='fa fa-external-link-alt'></i> ${task.name}`,
							()=>window.open(Utils.getUrl('neighbours', task.uuid, `${this.uuid}/${this.json.collection}`), '_blank').focus()
						);
				}
			}
			// review links
			let reviewableTasks = this.tasks.filter((t)=>t.type!="feature" && this.json.labels.find(a=>a.task==t.uuid));
			if(reviewableTasks.length > 0){
				let sub = this.menu.addSubMenu(`Review`);
				for(let task of reviewableTasks){
					sub
						.addItem(`<i class='fa fa-external-link-alt'></i> ${task.name}`,
							()=>window.open(Utils.getUrl('review', this.json.collection, `${task.uuid}|item=${this.uuid}`), '_blank').focus()
						);
				}
			}
		}

		this._ready = true;
	}


	/**
	 *
	 * @param {Number[][]} polygons
	 * @param {Number} baseHue
	 * @param {Number} hueStep
	 */
	renderPolygonsToCanvas(polygons, baseHue =  0, hueStep = 0, fill = false, fillColor = null){
		let canvas = document.createElement('canvas');
		canvas.classList.add('overlay');
		canvas.width = this.width;
		canvas.height = this.height;

		let ctx = canvas.getContext("2d");

		// draw the multiple polygons that make up an instance
		let hue = baseHue;
		for(let polygon of polygons){
			ctx.beginPath();
			let x = polygon[0];
			let y = polygon[1];
			ctx.moveTo(x,y);
			for(let i = 0; i < polygon.length; i+=2){
				ctx.lineTo(polygon[i],polygon[i+1]);
			}
			ctx.lineTo(x,y);
			if(fill){
				ctx.fillStyle = fillColor ?? `hsl(${hue}, 100%, 50%)`;
				ctx.fill();
			}else{
				// ! this line will appear thin on large images due to rescaling
				ctx.lineWidth = 3;
				ctx.strokeStyle = `hsl(${hue}, 100%, 50%)`;
				ctx.stroke();
			}
			hue+=hueStep;
		}

		return canvas;
	}

	/**
	 *
	 * @param {Number[]} box xywh
	 * @param {Number} baseHue
	 * @param {Number} hueStep
	 */
	renderBoxToCanvas(box, baseHue =  0, hueStep = 0, fill = false, fillColor = null, invert = false){
		let canvas = document.createElement('canvas');
		canvas.width = this.width;
		canvas.height = this.height;
		let ctx = canvas.getContext("2d");
		let hue = baseHue;
		if(fill){
			if(invert){
				ctx.fillStyle = fillColor ?? `hsl(${hue}, 100%, 50%)`;
				ctx.fillRect(0, 0, canvas.width, canvas.height);
				ctx.clearRect(box[0], box[1], box[2], box[3]);
			}else{
				ctx.fillStyle = fillColor ?? `hsl(${hue}, 100%, 50%)`;
				ctx.fillRect(box[0], box[1], box[2], box[3]);
			}
		}else{
			ctx.lineWidth = 3;
			ctx.strokeStyle = `hsl(${hue}, 100%, 50%)`;
			ctx.strokeRect(box[0], box[1], box[2], box[3]);
		}
		return canvas;
	}

	cutoutBoxesFromCanvas(boxes, fillColor){
		let canvas = document.createElement('canvas');
		canvas.width = this.width;
		canvas.height = this.height;

		let ctx = canvas.getContext("2d");
		ctx.fillStyle = fillColor ?? `hsl(${hue}, 100%, 50%)`;
		ctx.fillRect(0, 0, canvas.width, canvas.height);

		for(let box of boxes){
			ctx.clearRect(box[0], box[1], box[2], box[3]);
		}

		return canvas;
	}

	async ready(){
		while(!this._ready) {
			await UI.utils.sleep(10);
		}
	}

	get width(){
		return this.json.image.width;
	}

	get height(){
		return this.json.image.height;
	}

	addLabel(newChild){
		this.querySelector('content').appendChild(newChild);
	}

}

window.customElements.define("ui-item", ItemPanel);
export default ItemPanel;
