import * as UI from "automaton-ui";
import API from "/scripts/API.js";

import ItemPanel from '/component/item/ItemPanel.js';

import * as Utils from "/scripts/Utils.js";

const MAX_SEQUENCE_COUNT = 10;

/**
 * Component for reviewing the annotations (or labels) create for a task
 *
 */
class Review extends UI.BasicElement{

	#busy = true;

	itemAnnotation;
	itemPanel;
	itemUUID;

	#bucket;
	#sequenceCount=0;

	// when a modal is up the background is disabled
	modal;

	constructor(collection, task){
		super();

		this.collectionUUID = collection;
		this.taskUUID = task;

		this.task = null;
		this.bucket = null;

		this.annotations = [];
		this.body = null;
		this.footer = null;

		this.init();
	}

	set bucket(value){
		if(this.#bucket == value) {
			return;
		}
		// show a thing to call attention to the fact the bucket has changed
		this.#bucket = value;
		if(this.modal){
			this.modal.close();
			this.modal = null;
		}
		this.modal = new UI.Modal(
			`Now seeing items with bucket:<br/><ui-badge><h1>${this.bucket}</h1></ui-badge><br/>`,
			{
				dismissable: false,
				buttons: "<ui-spacer></ui-spacer><ui-cancel>OK</ui-cancel><ui-key>c</ui-key>"
			}).show();
		this.modal.panel.onclick=()=>{
			this.modal.close();this.modal = false;
		};
	}

	get bucket(){
		return this.#bucket;
	}

	get busy(){
		return this.#busy || this.modal;
	}

	remove(){
		super.remove();
		this.body.remove();
		return this;
	}

	async init(){
		this.style.width = "100%";
		this.style.height = "100%";

		let task = await API.task(this.taskUUID).get();
		this.task = task;

		// content of this element determined by the hash data in the url bar eg #item=UUID
		this.body = new UI.HashManager('item');
		this.body.hash = null;
		this.style.width = "100%";
		this.style.height = "100%";
		this.append(this.body);

		this.footer = document.createElement('footer');
		this.append(this.footer);
		this.footer.append(
			new UI.Button("FAIL <ui-key>x</ui-key>", ()=>this.reviewCurrentItem(false), {icon: 'fa-times', style: 'error'}),
			new UI.Button("SKIP <ui-key>.</ui-key>", ()=>this.reviewCurrentItem(null), {icon: 'fa-forward'}),
			new UI.Button("PASS <ui-key>v</ui-key>", ()=>this.reviewCurrentItem(true), {icon: 'fa-check', style: 'success'}));

		this.body.setCss('--timing', '200ms');

		this.body.handler('{uuid}', async ({uuid})=>{
			if(!this.closest('body')){
				// we're not on the page - remove the hashlistener...
				this.body.remove();
				return;
			}

			if(this.modal){
				this.modal.close();
				this.modal = null;
			}
			// If there is no uuid - we've completed the review
			if(uuid == null){
				this.footer.style.display = "none";
				// todo create review overview panel
				return [
					new UI.Panel("Nothing to review :)"), UI.HashManager.DIRECTION.TOP];
			}
			this.itemUUID = uuid;

			// otherwise fetch the annotation
			let annotation = await this.loadAnnotations(uuid);
			if(annotation == null) {
				return;
			}

			this.footer.style.display = "flex";

			this.itemAnnotation = annotation;

			let panel = new UI.Panel(`<div style="display:flex"><div class="item"></div><div class="sidebar"></div></div>`);
			this.itemPanel = panel;

			let itemElement = new ItemPanel(uuid, {annotations: this.taskUUID});
			panel.querySelector('.item').append(itemElement);

			let div = document.createElement('div');

			if(annotation.data.reviewed){
				if(annotation.data.reviewed == "PASS"){
					itemElement.classList.add("success");
				}else{
					itemElement.classList.add("error");
				}
			}

			await itemElement.ready();

			switch(task.type){
				case "bucket":
					div.innerHTML = `<span> Assigned Bucket</span><br/><ui-badge><h1>${annotation.data.labels.bucket}</h1></ui-badge>`;
					this.bucket = annotation.data.labels.bucket;
					break;
				case "box":
					div.innerHTML = `<h4>Confirm that this image:</h4>
					<ul>
						<li>Contains <b>${annotation.data.labels?.box?.length ?? 0}</b> valid Bounding Boxes</li>
						<li>The boxes closely crop instances</li>
						<li>No instance is unlabelled</li>
					</ul>`;
					break;
				case "polygon":{
					let polygonInfo = annotation.data.labels.polygon.map(
						(instance,index)=>`<li><b style="color:${itemElement.instanceColor(index)}">Instance ${index+1}</b> has <b>${instance.length}</b> necessary polygons</li>`)
						.join('\n');
					div.innerHTML = `
					<h4>Confirm that this image:</h4>
					<ul>
						<li>Contains <b>${annotation.data.labels.polygon.length}</b> valid multi-polygon Segmentations
							<ul>
								${polygonInfo}
							</ul>
						</li>
						<li>The polygons closely crop instances</li>
						<li>No instance is unlabelled</li>
					</ul>`;
					break;
				}
				case "multi-class-box":{
					let classes = annotation.data.labels.bucket;
					let boxInfo = annotation.data.labels.box.map(
						(_,index)=>`<li><b style="color:${itemElement.instanceColor(index)}">${classes[index]}</b>`)
						.join('\n');
					let possibleClasses = task.buckets.map((instance,index)=>`<li>${instance["key"]}</li>`).join('\n');
					div.innerHTML = `
					<h4>Confirm that this image:</h4>
					<ul>
						<li>Contains <b>${annotation.data.labels.box.length}</b> valid Bounding Boxes
							<ul>
								${boxInfo}
							</ul>
						</li>
						<li>The boxes closely crop instances</li>
						<li>No instance is unlabelled</li>
					</ul>
					<h4>Possible classes:</h4>
					<ul>
						${possibleClasses}
					</ul>`;
					break;
				}
				case "multi-class-polygon":{
					let classes = annotation.data.labels.bucket;
					let polygonInfo = annotation.data.labels.polygon.map(
						(instance,index)=>`<li><b style="color:${itemElement.instanceColor(index)}">${classes[index]}</b> has <b>${instance.length}</b> necessary polygons</li>`)
						.join('\n');
					let possibleClasses = task.buckets.map((instance,index)=>`<li>${instance["key"]}</li>`).join('\n');
					div.innerHTML = `
					<h4>Confirm that this image:</h4>
					<ul>
						<li>Contains <b>${annotation.data.labels.polygon.length}</b> valid multi-polygon Segmentations
							<ul>
								${polygonInfo}
							</ul>
						</li>
						<li>The polygons closely crop instances</li>
						<li>No instance is unlabelled</li>
					</ul>
					<h4>Possible classes:</h4>
					<ul>
						${possibleClasses}
					</ul>`;
					break;
				}
				default:
					div.innerHTML = `???`;
			}
			panel.querySelector('.sidebar').append(div);
			panel.querySelector('.sidebar').append(new UI.Spacer(), `Labelled by: ${annotation.data.author}`);
			this.#busy = false;

			return [panel, UI.HashManager.DIRECTION.BOTTOM];
		});

		this.body.hashChange();
		if(!this.body.value) {
			await this.goNext();
		}
	}

	async reviewCurrentItem(isPass){
		if(this.busy) {
			return;
		}
		this.#busy = true;
		if(isPass!==null){
			let value = isPass?"PASS":"FAIL";
			this.itemPanel.classList.add(isPass?"success":"error");
			// remove the failed annotation
			this.itemAnnotation.data.reviewed = value;
			// store the result
			await API.background.item(this.itemUUID).task(this.taskUUID).review(value);
		}
		// go to next Item
		await this.goNext();
	}

	async goNext(){
		this.#busy = true;

		let [preferClass, avoidClass] = this.getPreferAvoidState();
		let labelUUID = await this.loadLabelUUIDToReview(this.taskUUID, this.collectionUUID, preferClass, avoidClass);

		// check if all labels have been reviewed
		if(labelUUID == null){
			this.body.set(null);
			this.footer.style.display = "none";
			return;
		}

		// set label
		this.body.set(labelUUID);
	}

	async loadAnnotations(uuid){
		if (this.lastLabelLoaded != null && this.lastLabelLoaded.uuid == uuid){
			return this.lastLabelLoaded;
		}

		return await API.background.item(uuid).task(this.taskUUID).label.get(true);
	}

	async loadLabelUUIDToReview(taskUUID, collectionUUID, preferClass, avoidClass){
		let item = await API.background.task(taskUUID).unreviewedLabel(collectionUUID, preferClass, avoidClass);
		this.lastItemLoaded = item;
		return item?.item;
	}

	getPreferAvoidState(){
		// functionality only supported for bucket class
		if(this.task.type !== "bucket"){
			return [null, null];
		}

		// if we've done the maximum number of reviews in a row change bucket!
		this.#sequenceCount++;
		if(this.#sequenceCount >= MAX_SEQUENCE_COUNT){
			this.#sequenceCount = 1;
			return [null, this.bucket];
		}

		// return current bucket
		return [this.bucket, null];
	}

}

window.customElements.define("ui-review", Review);
export default Review;
