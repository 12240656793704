import * as UI from "automaton-ui";


export default class TagSelect extends UI.BasicElement{

	constructor(value){
		super();

		this.selectedTags = new Set(value.selected);
		this.possibleTags = new Set(value.possible.sort());

		this.setupTags();
	}

	setupTags(){
		// clear existing tag badges
		this.innerHTML = "";

		for(let tag of this.possibleTags){
			// create tag element
			let tagBadge = new UI.Badge(tag);
			tagBadge.classList.add("entity-tag");
			tagBadge.classList.toggle('unselected', !this.selectedTags.has(tag));
			tagBadge.addEventListener('click', ()=>this.tagClicked(tag, tagBadge));
			this.append(tagBadge);
		}

		// create add button
		let addInput = UI.utils.htmlToElement('<input type="text" name="enter" class="enter" value="" id="lolz"/>');
		addInput.addEventListener('keypress', (event)=>{
			if (event.key === 'Enter') {
				this.addNewTag(addInput.value);
			}
		});
		let addBadge = new UI.Badge(addInput, {icon: 'fa-plus'});
		this.append(addBadge);
	}

	tagClicked(tag, tagBadge){
		// toggle selected status of tag and update badge state
		if (this.selectedTags.has(tag)){
			this.selectedTags.delete(tag);
			tagBadge.classList.toggle('unselected', true);
		} else {
			this.selectedTags.add(tag);
			tagBadge.classList.toggle('unselected', false);
		}
	}

	addNewTag(tag){
		// clean input
		tag = tag.trim().toLowerCase();

		// ignore invalid tags
		if (tag == '') {
			return;
		}

		if (!this.possibleTags.has(tag)){
			this.possibleTags.add(tag);
		}
		this.selectedTags.add(tag);

		// redraw tags
		this.setupTags();
	}

	get value(){
		return {
			selected: [...this.selectedTags],
			possible: [...this.possibleTags]
		};
	}

}
window.customElements.define("ui-ui-tagselect", TagSelect);