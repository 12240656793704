import * as UI from "automaton-ui";
import API from "/scripts/API.js";
import ItemPanel from "/component/item/ItemPanel.js";

const KEYBOARD_SHORTCUTS = [
	'1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
	'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p',
	'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l',
	'z', 'x', 'c', 'v', 'b', 'n', 'm'
];

/**
 *
 * Utility for putting items into buckets
 *
 */
class BucketAnnotator extends UI.BasicElement{

	constructor(collectionUUID, taskUUID){
		super();

		this.collectionUUID = collectionUUID;
		this.taskUUID = taskUUID;

		this.init();

		this.cards = [];

		this.mode = null;

		this.repopulateTimer = null;

		this.data = null;
	}

	async updateProgress(){
		let progress = this.querySelector("progress");
		progress.setAttribute("max", this.data.count);
		progress.setAttribute("value", ''+this.data.labelled);
	}

	async init(){
		// info on the task
		let task = await API.task(this.taskUUID).get();

		// bail out where the task wont work with this annotator
		if(task.type != "bucket" || !task.buckets || !task.buckets.length){
			this.append(new UI.Panel("Invalid task configuration", {}));
			return;
		}

		let size = document.createElement('select');
		let options = [
			'1 x 1', '2 x 1', '3 x 1', '4 x 1', '5 x 1',
			'1 x 2', '2 x 2', '3 x 2', '4 x 2', '5 x 2',
			'1 x 3', '2 x 3', '3 x 3', '4 x 3', '5 x 3',
		];
		size.innerHTML = options.map(o=>`<option>${o}</option>`).join('');
		size.value = '5 x 2';
		size.addEventListener('change',(e)=>{
			this.showGrid(...size.value.split(' x ').map(v=>parseInt(v)));
		});
		this.append(size);

		// element to indicate how complete the task is
		let progress = document.createElement('progress');
		this.append(progress);

		// create holding element for item cards
		let cards = document.createElement('div');
		cards.classList.add('cards');
		this.append(cards);

		// holding element for the buckets
		let buckets = document.createElement('div');
		buckets.classList.add('buckets');
		this.append(buckets);

		// create element for each bucket
		let keyIndex = 0;
		for(let bucket of task.buckets){
			let keyboardShortcut = KEYBOARD_SHORTCUTS[keyIndex];
			let title = `<ui-key>${keyboardShortcut ?? ''}</ui-key> ${bucket.key}`;
			let ele = new UI.Panel('', {title: title, clazz: 'bucket'});
			if(this.mode==null){
				this.mode = bucket.key;
			}

			ele.classList.toggle('active', this.mode == bucket.key);
			ele.dataset['bucket'] = bucket.key;
			buckets.append(ele);

			// click the header to activate the bucket
			ele.addEventListener('click', (evt)=>{
				this.mode = bucket.key;
				buckets.childNodes.forEach(ele=>{
					// @ts-ignore
					ele.classList.toggle('active', this.mode == ele.dataset['bucket']);
				});

				// control or shift click on bucket title moves all items in!
				if (evt.ctrlKey || evt.shiftKey) {
					this.moveAllToBucket();
				}
			});

			keyIndex++;
		}

		//
		this.showGrid(...size.value.split(' x ').map(v=>parseInt(v)));
	}

	async showGrid(columns,rows){
		let cardEle = this.querySelector('.cards');
		let bucketsEle = this.querySelector('.buckets');
		// set the css variables to resize the visual grid
		this.setCss("--columns", columns);
		this.setCss("--rows", rows);
		// create context menu for cards
		let contextMenu = new UI.ContextMenu();
		contextMenu.addItem("<i class='fa fa-window-restore'></i> Detail", (card)=>{
			if(card.item){
				new UI.Modal(new ItemPanel(card.item, {annotations: true, controls: true})).show();
			}
		});
		// create item cards, which will be blank initially
		for( let i = 0; i < columns*rows; i++){
			let card = new ItemCard();
			card.flipped = false;
			this.cards.push(card);
			cardEle.append(card);

			// add context menu
			contextMenu.for(card);

			// disable drag behaviour
			card.ondragstart = ()=>false;

			// clicking a card sends it to the active bucket
			card.onclick = async ()=>{
				if(card.item){
					let uuid = card.item;
					card.item = null;
					// add the item to the bucket
					await API.background.item(uuid).task(this.taskUUID).label.create({
						bucket: this.mode
					});

					this.data.labelled++;
					this.updateProgress();

					// create a element in the bucket to allow the user to reverse this action
					let img = document.createElement('img');
					img.src = `/image/${uuid}`;
					img.onclick = async (event)=>{
						event.preventDefault();
						event.stopPropagation();
						img.remove();
						this.data.labelled--;
						await API.background.item(uuid).task(this.taskUUID).delete(null);
						this.updateProgress();
						this.repopulate();
					};
					bucketsEle.querySelector('.active content').prepend(img);

					// indicate we wish to populate the cards
					this.repopulate();
				}
			};
		}
		// remove any excess cards
		while(this.cards.length>columns*rows){
			// remove from set
			let card = this.cards.pop();
			// remove from page
			card.remove();
		}

		// add items to the blank item cards
		this.populate();
	}

	moveAllToBucket(){
		for(let card of this.cards){
			card.click();
		}
	}

	/**
	 * Indicate we wish to populate the cards again
	 * but don't do it immediately as the user will likely click another card soon;
	 * so wait for a gap of (1000)ms to do so
	 */
	repopulate(){
		if(this.repopulateTimer != null) {
			clearTimeout(this.repopulateTimer);
		}
		this.repopulateTimer = setTimeout(this.populate.bind(this), 1000);
	}

	async populate(){
		// grab the data for this task+collection combo
		this.data = await API.background.task(this.taskUUID).collection(this.collectionUUID);

		// update the progress bar (as other may have labelled some)
		this.updateProgress();

		// randomise the items
		let items = UI.utils.shuffle(this.data.unlabelledItems);

		// filter items we are already showing
		let uuids = this.cards.map(ele=>ele.item);
		items = items.filter(item=>!uuids.includes(item.uuid));

		// for each card placeholder, add an item to it (if it is blank)
		for(let card of this.cards){
			if(items.length<1) {
				break;
			}
			if(card.item == null){
				let item = items.pop();
				card.item = item.uuid;
			}
		}
	}

}

window.customElements.define("ui-buckets", BucketAnnotator);
export default BucketAnnotator;
class ItemCard extends UI.Card{

	constructor(){
		super();

		this.item = null;
	}

	set item(uuid){
		this.uuid = uuid;

		if(this.uuid){
			// HOTFIX - should use this.setContent() - but this has been broken in the latest release of the automaton-ui library
			this.querySelector('.card').innerHTML = `<img src="/image/${uuid}"/>`;
			this.flipped = true;
		}else{
			this.flipped = false;
		}
	}

	get item(){
		return this.uuid;
	}

}
window.customElements.define('ui-item-card', ItemCard);
