import UI from "automaton-ui";
import API from "/scripts/API.js";
import TagSelect from '/component/models/TagSelect.js';
import WorldFilter from '/component/global/WorldFilter.js';

class GrowInput extends UI.BasicElement{

	constructor(value){
		super();

		let makeInput = (name)=>{
			let box = UI.html(`<div><label style="display: inline-block; width: 45px; padding: 10px; text-align:right;">${name}</label><span class="value"><input type='number' placeholder="0%"/></span></div>`);
			this.append(box);
			return box.querySelector('input');
		};

		this.left = makeInput("Left");
		this.top = makeInput("Top");
		this.right = makeInput("Right");
		this.bottom = makeInput("Bottom");

		if(value && value.length==4){
			this.left.value = value[0];
			this.top.value = value[1];
			this.right.value = value[2];
			this.bottom.value = value[3];
		}
	}

	get value(){
		// @ts-ignore
		return [
			parseFloat(this.left.value) || 0,
			parseFloat(this.top.value) || 0,
			parseFloat(this.right.value) || 0,
			parseFloat(this.bottom.value) || 0];
	}

}
window.customElements.define('ui-growinput', GrowInput);

class AugmentationInput extends UI.BasicElement{

	constructor(value){
		super();

		this.name = value?.name ?? 'ColorJiggle';
		this.params = value?.params ?? this.getPossibleParams(this.name);

		this.redraw();
	}

	getPossibleParams(name) {
		switch (name) {
			case 'ColorJiggle':
				return {
					'brightness': 0.0,
					'contrast': 0.0,
					'saturation': 0.0,
					'hue': 0.0,
					'p': 1.0
				};
			case 'RandomAffine':
				return {
					'degrees': 0.0,
					'p': 1.0
				};
			case 'RandomHorizontalFlip':
				return {
					'p': 1.0
				};
			case 'RandomChannelShuffle':
				return {
					'p': 1.0
				};
			case 'RandomErasing':
				return {
					'scale': [
						0.02, 0.33
					],
					'ratio': [
						0.3, 3.3
					],
					'value': 0,
					'p': 0.5
				};
			case 'RandomElasticTransform':
				return {
					'kernel_size': [
						63, 63
					],
					'sigma': [
						32, 32
					],
					'alpha': [
						1.0, 1.0
					],
					'align_corners': false,
					'mode': 'bilinear',
					'padding_mode': 'zeros',
					'p': 0.5
				};
			default:
				throw Error(`No parameters defined for augmentation '${name}`);
		}
	}

	async redraw(){
		this.innerHTML = `<table>
			<tr><th>Name</th><td class="augmentationName"></td></tr>
			<tr><th>Params</th><td class="value augmentationParams"></td></tr>
		</table>`;

		this.querySelector('.augmentationName').append(this.buildName());
		this.querySelector('.augmentationParams').append(this.buildParams());
	}

	buildName() {
		let options = [
			'ColorJiggle',
			'RandomAffine',
			'RandomHorizontalFlip',
			'RandomChannelShuffle',
			'RandomErasing',
			'RandomElasticTransform'
		];

		let buildOption = (name) => `<option value="${name}" ${name == this.name ? 'selected' : ''}>${name}</option>`;
		let select = UI.html(`<select>${options.map(buildOption).join('\n')}</select>`);

		select.onchange = (e) => {
			this.name = e.target.value;
			this.params = this.getPossibleParams(this.name);
			this.redraw();
		};

		return select;
	}

	buildParams() {
		let container = UI.html('<div class="array ROW"></div>');
		for (let param of Object.entries(this.params)) {
			container.append(this.buildParam(param));
		}
		return container;
	}

	buildParam(param) {
		let paramInput = UI.html(`<input type="string" name="${param[0]}" value="${param[1]}">`);
		paramInput.onchange = (e) => {
			let value = e.target.value;

			// split into array if values are comma separated
			if (value.includes(',')) {
				value = value.split(',');
				value = value.map(x => !isNaN(x) ? parseFloat(x) : x);
			} else if (!isNaN(value)) { // convert any numbers to floats
				value = parseFloat(value);
			}

			this.params[e.target.name] = value;
		};

		let element = UI.html(`<ui-basic class="item"><table><tr><th>${param[0]}</th><td class="value paramInput"></td></tr></table></ui-basic>`);
		element.querySelector('.paramInput').append(paramInput);

		return element;
	}

	get value() {
		return {
			name: this.name,
			params: this.params
		};
	}

}

window.customElements.define('ui-augmentationinput', AugmentationInput);

function FormTemplate(dataset){
	return [
		{
			key: 'name',
			name: "Name",
			type: 'string'
		},
		{
			key: "tags",
			name: "Tags",
			type: (elementValue, jsonKey, element)=>{
				return new TagSelect(elementValue);
			}
		},
		"Dataset Configuration:header",
		{
			key: 'dataset',
			name: 'Dataset',
			type: 'list',
			options: async ()=>{
				// make sure the WorldFilter has generated
				await WorldFilter.ready();

				let datasets = await API.dataset().list();
				return datasets
					.filter(d=>d.ready) // ignore pending datasets
					.filter(d=>d.name) // ignore datasets with null names
					.filter(d=>WorldFilter.filter(d)) // ignore datasets not in current world
					.sort((a,b)=>a.name.localeCompare(b.name)) // sort alphabetically
					.map(d=>{
						return {value: d.uuid, name: d.name};
					});
			},
			default: dataset.uuid
		},
		{
			key: 'balance',
			type: 'compound',
			children: [
				{
					key: 'ratio',
					name: "Train/Val Split",
					type: 'list',
					format: 'number',
					options:  [
						{value:"80", name: "80 / 20"},
						{value:"100", name: "100 / 0 (full training)"},
						{value:"0", name: "0 / 100 (full evaluation)"}
					]
				},
				{
					key: 'shuffle',
					name: 'Shuffle',
					type: 'toggle',
					default: true,
					hidden: json => json.balance.ratio=='0' || json.balance.ratio=='100',
				},
				{
					key: 'class',
					name: "Class Balancing",
					type: 'compound',
					children: [
						{
							key: 'mode',
							name: "Mode",
							type: 'list',
							options:  [
								{value:"none", name: "None"},
								{value:"count", name: "Balance by Count"}
							]
						}
					],
					default: "none"
				}
			]
		},
		{
			key: 'auto_evaluation',
			name: "Auto run evaluation",
			type: 'toggle',
			default: false,
			hidden: json => json.balance.ratio=='0' || json.balance.ratio=='100',
		},
		{
			key: 'evaluation_mode',
			name: 'Evaluation Mode',
			hidden: json => json.balance.ratio == '100',
			type: 'list',
			options:  [
				{value:"default", name: "Default"},
				{value:"class-overlap", name: "Class Overlap"}
			]
		},
		"Framework Configuration:header",
		{
			key: 'framework',
			type: 'list',
			options:  [
				// TODO build this list dynamically for the dataset!
				{value:"knn-classifier", name:"KNN Classifier"},
				{value:"is-x-service", name:"Is X Classifier"},
				{value:"detectron-service", name:"Detectron Classifier"},
				{value:"detr-service", name:"Detr Boxes"},
				{value:"embedding-service", name:"Embedding Classifier"},
				{value:"yolo-service", name:"YOLO Boxes"},
				{value:"yolo-classifier", name:"YOLO Classifer"},
				{value:"yolo-polygon", name:"YOLO Segmentation"}
			],
			default: "is-x-service"
		},
		{
			key: 'config',
			type: 'compound',
			hidden: json=>json.framework!='knn-classifier',
			children: [
				{
					key: "n_neighbors",
					type: "number",
					default: 5
				},
				{
					key: "metric",
					name: "distance metric",
					type: "dropdown",
					options: [
						"euclidean",
						"manhattan",
						"chebyshev",
						"minkowski",
						"wminkowski",
						"seuclidean",
						"mahalanobis"
					],
					default: 'minkowski'
				},
				{
					key: "p",
					name: "minkowski p",
					hidden: json=>json.config?json.config.metric!='minkowski':false,
					type: "number",
					default: 2
				},
				{
					key: "use_pca",
					name: "Enable PCA",
					type: "toggle",
					default: false
				},
				{
					key: "pca_dimensions",
					name: "PCA Dimensions",
					hidden: (json)=>!json.config?.use_pca,
					type: "number",
					default: 64
				}
			]
		},
		{
			key: 'config',
			type: 'compound',
			hidden: json=>json.framework!='yolo-classifier',
			children: [
				{
					key: "base",
					name: "Base model",
					type: 'list',
					options: [
						// v8
						{value: "yolov8n-cls.pt", name: "Yolo 8 nano (12mb)"},
						{value: "yolov8s-cls.pt", name: "Yolo 8 small (?mb)"},
						{value: "yolov8m-cls.pt", name: "Yolo 8 medium (100mb)"},
						{value: "yolov8l-cls.pt", name: "Yolo 8 large (166mb)"},
						{value: "yolov8x-cls.pt", name: "Yolo 8 extra-large (?mb)"},
					],
					default: "yolov8n-cls.pt"
				},
				{
					key: "num_epochs",
					type: "number",
					default: 50
				},
				{
					key: "image_size",
					type: "number",
					default: 224
				},
				{
					key: "hparams",
					type: "object",
					children: [
						{
							key: "degrees",
							type: "number",
							default: 0
						},
						{
							key: "fliplr",
							default: 0,
							type: 'list',
							format: 'number',
							options:  [
								{value: "0", name: "OFF"},
								{value: "0.5", name: "ON"}
							]
						}
					]
				}
			]
		},
		{
			key: 'config',
			type: 'compound',
			hidden: json=>json.framework!='yolo-service',
			children: [
				{
					key: "base",
					name: "Base model",
					type: 'list',
					options: [
						// v8
						{value: "yolov8n.pt", name: "Yolo 8 nano (3.2M Param)"},
						{value: "yolov8s.pt", name: "Yolo 8 small (11.2M Param)"},
						{value: "yolov8m.pt", name: "Yolo 8 medium (25.9M Param)"},
						{value: "yolov8l.pt", name: "Yolo 8 large (43.7M Param)"},
						{value: "yolov8x.pt", name: "Yolo 8 extra-large (68.2M Param)"},

						// v9
						{value: "yolov9t.pt", name: "Yolo 9 tiny (2.0M Param)"},
						{value: "yolov9s.pt", name: "Yolo 9 small (7.2M Param)"},
						{value: "yolov9m.pt", name: "Yolo 9 medium (20.1M Param)"},
						{value: "yolov9c.pt", name: "Yolo 9 large (25.5M Param)"},
						{value: "yolov9e.pt", name: "Yolo 9 extra large (58.1M Param)"},

						// v10
						{value: "yolov10n.pt", name: "Yolo 10 nano (2.3M Param)"},
						{value: "yolov10s.pt", name: "Yolo 10 small (7.2M Param)"},
						{value: "yolov10m.pt", name: "Yolo 10 medium (15.4M Param)"},
						{value: "yolov10l.pt", name: "Yolo 10 large (24.4M Param)"},
						{value: "yolov10x.pt", name: "Yolo 10 extra-large (29.5M Param)"},
					],
					default: "yolov8n.pt"
				},
				{
					key: "num_epochs",
					type: "number",
					default: 50
				},
				{
					key: "image_size",
					type: "number",
					default: 640
				},
				{
					key: "hparams",
					type: "object",
					children: [
						{
							key: "degrees",
							type: "number",
							default: 0
						},
						{
							key: "fliplr",
							default: 0,
							type: 'list',
							format: 'number',
							options:  [
								{value: "0", name: "OFF"},
								{value: "0.5", name: "ON"}
							]
						}
					]
				}
			]
		},
		{
			key: 'config',
			type: 'compound',
			hidden: json=>json.framework!='yolo-polygon',
			children: [
				{
					key: "base",
					name: "Base model",
					type: 'list',
					options: [
						// v8
						{value: "yolov8n-seg.pt", name: "Yolo 8 nano (12mb)"},
						{value: "yolov8s-seg.pt", name: "Yolo 8 small (?mb)"},
						{value: "yolov8m-seg.pt", name: "Yolo 8 medium (100mb)"},
						{value: "yolov8l-seg.pt", name: "Yolo 8 large (166mb)"},
						{value: "yolov8x-seg.pt", name: "Yolo 8 extra-large (?mb)"},

						// v9
						{value: "yolov9c-seg.pt", name: "Yolo 9 c (100mb)"},
						{value: "yolov9e-seg.pt", name: "Yolo 9 e (?mb) - > 14gb VRAM required!"},

						// v10
					],
					default: "yolov8n-seg.pt"
				},
				{
					key: "num_epochs",
					type: "number",
					default: 100
				},
				{
					key: "image_size",
					type: "number",
					default: 640
				},
				{
					key: "hparams",
					type: "object",
					children: [
						{
							key: "degrees",
							type: "number",
							default: 0
						},
						{
							key: "fliplr",
							default: 0,
							type: 'list',
							format: 'number',
							options:  [
								{value: "0", name: "OFF"},
								{value: "0.5", name: "ON"}
							]
						}
					]
				}
			]
		},
		{
			key: 'config',
			type: 'compound',
			hidden: json=>json.framework!='detr-service',
			children: [
				{
					key: "num_epochs",
					type: "number",
					default: 10
				},
				{
					key: "num_workers",
					type: "number",
					default: 4
				},
				{
					key: "batch_size",
					type: "number",
					default: 10
				},
				{
					key: "optimizer_learning_rate",
					type: "number",
					default: 0.001
				}
			]
		},
		{
			key: 'config',
			type: 'compound',
			hidden: json=>json.framework!='is-x-service',
			children: [
				{
					key: "model_loader",
					name: "Base model",
					type: 'list',
					options: [
						// pretrained - should allow good results with small datasets
						{value: "models.resnet18(pretrained=True)", name: "Resnet 18 (pretrained)"},
						{value: "models.resnet34(pretrained=True)", name: "Resnet 34 (pretrained)"},
						{value: "models.resnet101(pretrained=True)", name: "Resnet 101 (pretrained)"},
						{value: "models.resnet152(pretrained=True)", name: "Resnet 152 (pretrained)"},

						{value: "models.alexnet(pretrained=True)", name: "Alexnet (pretrained)"},
						{value: "models.squeezenet1_0(pretrained=True)", name: "squeezenet (pretrained)"},
						{value: "models.vgg16(pretrained=True)", name: "vgg (pretrained)"},
						{value: "models.densenet161(pretrained=True)", name: "densenet 161 (pretrained)"},
						// {value: "models.inception_v3(pretrained=True)", name: "inception_v3 (pretrained)"}, // Excluded "This requires scipy to be installed" & it requires a different image size (requires patching is-x-service method: utils.get_image_transform_train)
						// {value: "models.googlenet(pretrained=True)", name: "googlenet (pretrained)"}, // Excluded "This requires scipy to be installed"
						{value: "models.shufflenet_v2_x1_0(pretrained=True)", name: "shufflenet v2 (pretrained)"},
						{value: "models.mobilenet_v2(pretrained=True)", name: "MobileNet v2 (pretrained)"},
						{value: "models.mobilenet_v3_small(pretrained=True)", name: "MobileNet v3 small (pretrained)"},
						{value: "models.mobilenet_v3_large(pretrained=True)", name: "MobileNet v3 large (pretrained)"},
						{value: "models.resnext50_32x4d(pretrained=True)", name: "ResNext 50 (pretrained)"},
						{value: "models.resnext101_32x8d(pretrained=True)", name: "ResNext 101 (pretrained)"},
						{value: "models.wide_resnet50_2(pretrained=True)", name: "Wide ResNet 50 (pretrained)"},
						{value: "models.wide_resnet101_2(pretrained=True)", name: "Wide ResNet 101 (pretrained)"},
						{value: "models.mnasnet1_0(pretrained=True)", name: "MNASNet (pretrained)"},

						// untrained models - you best have a LOT of data
						{value: "models.resnet18()", name: "Resnet 18"}
					],
					default: "models.resnet18(pretrained=True)"
				},
				{
					key: "freeze_base_model",
					name: "Freeze base model weights",
					type: "toggle",
					default: false
				},
				{
					key: "custom_top_enabled",
					name: "Custom top",
					type: "toggle",
					default: false
				},
				{
					key: "custom_top",
					name: "Custom top options",
					hidden: json => !json.config.custom_top_enabled,
					type: "compound",
					children: [
						{
							key: "dropout_enabled",
							name: "Dropout enabled",
							type: "toggle",
							default: false
						},
						{
							key: "dropout_p",
							name: "Dropout probability",
							hidden: json => json.config.custom_top ? !json.config.custom_top.dropout_enabled : true,
							type: "number",
							default: 0.2
						},
						{
							key: "hidden_units",
							name: "Hidden units",
							type: "array",
							style: "ROW",
							children: "number",
							default: [512]
						}
					]
				},
				{
					key: "num_workers",
					name: "Workers",
					type: "number",
					default: 4
				},
				{
					key: "batch_size",
					name: "Batch size",
					type: "number",
					default: 4
				},
				{
					key: "num_epochs",
					name: "Epochs",
					type: "number",
					default: 50
				},
				{
					key: "optimizer_type",
					name: "Optimizer",
					type: "select",
					options: [
						{value: "Adam", name: "Adam"},
						{value: "SGD", name: "SGD"}
					],
					default: 'SGD'
				},
				{
					key: "optimizer_learning_rate",
					name: "Learning rate",
					type: "number",
					default: 0.001
				},
				{
					key: "scheduler_enabled",
					name: "Scheduler",
					type: "toggle",
					default: false
				},
				{
					key: "scheduler",
					name: "Scheduler options",
					hidden: json => !json.config.scheduler_enabled,
					type: "compound",
					children: [
						{
							key: "step_size",
							name: "Step size",
							type: "number",
							default: 7
						},
						{
							key: "gamma",
							type: "number",
							default: 0.1
						}
					]
				},
				{
					key: "k_fold",
					name: "Enable K-Fold Cross-Validation",
					type: "toggle",
					default: false
				},
				{
					key: "crop_transform",
					name: "Crop transform",
					type: "compound",
					children: [
						{
							key: "enabled",
							type: "toggle",
							default: false
						},
						{
							key: "square",
							hidden: json => json.config.crop_transform ? !json.config.crop_transform.enabled : true,
							type: "toggle",
							default: false
						},
						{
							key: "grow",
							hidden: json => json.config.crop_transform ? !json.config.crop_transform.enabled : true,
							type: GrowInput,
						},
						{
							key: "respect_boundaries",
							name: "Respect boundaries",
							hidden: json => json.config.crop_transform ? !json.config.crop_transform.enabled : true,
							type: "toggle",
							default: false
						}
					]
				},
				{
					key: "fill_colour",
					name: "Fill colour",
					hidden: json => json.config.crop_transform ? !json.config.crop_transform.enabled : true,
					type: "array",
					style: "ROW",
					children: "number",
					default: [255,255,255]
				},
				{
					key: "train_transforms",
					type: "compound",
					children: [
						{
							key: "auto_augment_enabled",
							name: "Auto Augment",
							type: "toggle",
							default: false
						},
						{
							key: "auto_augment",
							name: "Policy",
							hidden: json => json.config.train_transforms ? !json.config.train_transforms.auto_augment_enabled : true,
							type: "list",
							options: [
								{value: "IMAGENET", name: "IMAGENET"},
								{value: "CIFAR10", name: "CIFAR10"},
								{value: "SVHN", name: "SVHN"}
							]
						},
						{
							key: "random_horizontal_flip",
							name: "Random horizontal flip",
							hidden: json => json.config.train_transforms ? json.config.train_transforms.auto_augment_enabled : true,
							type: "toggle",
							default: true
						},
						{
							key: "color_jitter_enabled",
							name: "Color jitter",
							hidden: json => json.config.train_transforms ? json.config.train_transforms.auto_augment_enabled : true,
							type: "toggle",
							default: false
						},
						{
							key: "color_jitter",
							name: "Color jitter options",
							hidden: json => json.config.train_transforms ? json.config.train_transforms.auto_augment_enabled || !json.config.train_transforms.color_jitter_enabled : true,
							type: "compound",
							children: [
								{
									key: "brightness",
									type: "number",
									default: 0
								},
								{
									key: "contrast",
									type: "number",
									default: 0
								},
								{
									key: "saturation",
									type: "number",
									default: 0
								},
								{
									key: "hue",
									type: "number",
									default: 0
								}
							]
						},
						{
							key: "gaussian_blur_enabled",
							name: "Gaussian blur",
							hidden: json => json.config.train_transforms ? json.config.train_transforms.auto_augment_enabled : true,
							type: "toggle",
							default: false
						},
						{
							key: "gaussian_blur",
							name: "Gaussian blur options",
							hidden: json => json.config.train_transforms ? json.config.train_transforms.auto_augment_enabled || !json.config.train_transforms.gaussian_blur_enabled : true,
							type: "compound",
							children: [
								{
									key: "kernel_size",
									type: "array",
									style: "ROW",
									children: "number",
									default: [5, 9]
								},
								{
									key: "sigma",
									type: "array",
									style: "ROW",
									children: "number",
									default: [0.1, 5]
								}
							]
						},
						{
							key: "random_perspective_enabled",
							name: "Random perspective",
							hidden: json => json.config.train_transforms ? json.config.train_transforms.auto_augment_enabled : true,
							type: "toggle",
							default: false
						},
						{
							key: "random_perspective",
							name: "Random perspective options",
							hidden: json => json.config.train_transforms ? json.config.train_transforms.auto_augment_enabled || !json.config.train_transforms.random_perspective_enabled : true,
							type: "compound",
							children: [
								{
									key: "distortion_scale",
									type: "number",
									default: 0.6
								},
								{
									key: "p",
									type: "number",
									default: 1.0
								}
							]
						},
						{
							key: "random_rotation_enabled",
							name: "Random rotation",
							hidden: json => json.config.train_transforms ? json.config.train_transforms.auto_augment_enabled : true,
							type: "toggle",
							default: false
						},
						{
							key: "random_rotation",
							name: "Random rotation options",
							hidden: json => json.config.train_transforms ? json.config.train_transforms.auto_augment_enabled || !json.config.train_transforms.random_rotation_enabled : true,
							type: "compound",
							children: [
								{
									key: "degrees",
									type: "array",
									style: "ROW",
									children: "number",
									default: [0, 180]
								}
							]
						},
						{
							key: "random_affine_enabled",
							name: "Random affine",
							hidden: json => json.config.train_transforms ? json.config.train_transforms.auto_augment_enabled : true,
							type: "toggle",
							default: false
						},
						{
							key: "random_affine",
							name: "Random affine options",
							hidden: json => json.config.train_transforms ? json.config.train_transforms.auto_augment_enabled || !json.config.train_transforms.random_affine_enabled : true,
							type: "compound",
							children: [
								{
									key: "degrees",
									type: "array",
									style: "ROW",
									children: "number",
									default: [30, 70]
								},
								{
									key: "translate",
									type: "array",
									style: "ROW",
									children: "number",
									default: [0.1, 0.3]
								},
								{
									key: "scale",
									type: "array",
									style: "ROW",
									children: "number",
									default: [0.5, 0.75]
								}
							]
						},
						{
							key: "random_crop_enabled",
							name: "Random crop",
							hidden: json => json.config.train_transforms ? json.config.train_transforms.auto_augment_enabled : true,
							type: "toggle",
							default: false
						},
						{
							key: "random_crop",
							name: "Random crop options",
							hidden: json => json.config.train_transforms ? json.config.train_transforms.auto_augment_enabled || !json.config.train_transforms.random_crop_enabled : true,
							type: "compound",
							children: [
								{
									key: "size",
									type: "array",
									style: "ROW",
									children: "number",
									default: [128, 128]
								}
							]
						},
						{
							key: "to_grayscale",
							name: "To grayscale",
							hidden: json => json.config.train_transforms ? json.config.train_transforms.auto_augment_enabled : true,
							type: "toggle",
							default: false
						}
					]
				}
			]
		},
		{
			key: 'config',
			type: 'compound',
			hidden: json=>json.framework!='embedding-service',
			children: [
				{
					key: "model_loader",
					name: "Base model",
					type: 'list',
					options: [
						// pretrained - should allow good results with small datasets
						{value: "models.resnet18(pretrained=True)", name: "Resnet 18 (pretrained)"},
						{value: "models.resnet34(pretrained=True)", name: "Resnet 34 (pretrained)"},
						{value: "models.resnet101(pretrained=True)", name: "Resnet 101 (pretrained)"},
						{value: "models.resnet152(pretrained=True)", name: "Resnet 152 (pretrained)"},

						{value: "models.alexnet(pretrained=True)", name: "Alexnet (pretrained)"},
						{value: "models.squeezenet1_0(pretrained=True)", name: "squeezenet (pretrained)"},
						{value: "models.vgg16(pretrained=True)", name: "vgg (pretrained)"},
						{value: "models.densenet161(pretrained=True)", name: "densenet 161 (pretrained)"},
						// {value: "models.inception_v3(pretrained=True)", name: "inception_v3 (pretrained)"}, // Excluded "This requires scipy to be installed" & it requires a different image size (requires patching is-x-service method: utils.get_image_transform_train)
						// {value: "models.googlenet(pretrained=True)", name: "googlenet (pretrained)"}, // Excluded "This requires scipy to be installed"
						{value: "models.shufflenet_v2_x1_0(pretrained=True)", name: "shufflenet v2 (pretrained)"},
						{value: "models.mobilenet_v2(pretrained=True)", name: "MobileNet v2 (pretrained)"},
						{value: "models.mobilenet_v3_small(pretrained=True)", name: "MobileNet v3 small (pretrained)"},
						{value: "models.mobilenet_v3_large(pretrained=True)", name: "MobileNet v3 large (pretrained)"},
						{value: "models.resnext50_32x4d(pretrained=True)", name: "ResNext 50 (pretrained)"},
						{value: "models.resnext101_32x8d(pretrained=True)", name: "ResNext 101 (pretrained)"},
						{value: "models.wide_resnet50_2(pretrained=True)", name: "Wide ResNet 50 (pretrained)"},
						{value: "models.wide_resnet101_2(pretrained=True)", name: "Wide ResNet 101 (pretrained)"},
						{value: "models.mnasnet1_0(pretrained=True)", name: "MNASNet (pretrained)"},

						// untrained models - you best have a LOT of data
						{value: "models.resnet18()", name: "Resnet 18"}
					],
					default: "models.resnet18(pretrained=True)"
				},
				{
					key: "freeze_base_model_weights",
					name: "Freeze all layers apart from last",
					type: "toggle",
					default: true
				},
				{
					key: "embedding_dims",
					name: "Number of dimensions in the last layer",
					type: "number",
					default: 128
				},
				{
					key: "num_workers",
					type: "number",
					default: 2
				},
				{
					key: "batch_size",
					type: "number",
					default: 16
				},
				{
					key: "num_epochs",
					type: "number",
					default: 10
				},
				{
					key: "optimizer_type",
					type: "select",
					options: [
						{value: "Adam", name: "Adam"},
						{value: "SGD", name: "SGD"}
					],
					default: 'SGD'
				},
				{
					key: "optimizer_learning_rate",
					type: "number",
					default: 0.001
				},
				{
					key: "crop_transform",
					name: "Crop transform",
					type: "compound",
					children: [
						{
							key: "enabled",
							type: "toggle",
							default: false
						},
						{
							key: "square",
							hidden: json => json.config.crop_transform ? !json.config.crop_transform.enabled : true,
							type: "toggle",
							default: false
						},
						{
							key: "grow",
							hidden: json => json.config.crop_transform ? !json.config.crop_transform.enabled : true,
							type: GrowInput,
						},
						{
							key: "respect_boundaries",
							name: "Respect boundaries",
							hidden: json => json.config.crop_transform ? !json.config.crop_transform.enabled : true,
							type: "toggle",
							default: false
						}
					]
				},
				{
					key: "fill_colour",
					name: "Fill colour",
					hidden: json => json.config.crop_transform ? !json.config.crop_transform.enabled : true,
					type: "array",
					style: "ROW",
					children: "number",
					default: [255,255,255]
				}
			]
		},
		{
			key: 'config',
			type: 'compound',
			hidden: json=>json.framework!='detectron-service',
			children: [
				{
					key: "model",
					type: "compound",
					children: [
						{
							key: "base",
							type: "list",
							options: [
								{
									value: "COCO-InstanceSegmentation/mask_rcnn_R_50_FPN_1x.yaml",
									name: "Segmentation - mask_rcnn_R_50_FPN_1x"
								},
								{
									value: "COCO-InstanceSegmentation/mask_rcnn_R_101_FPN_3x.yaml",
									name: "Segmentation - mask_rcnn_R_101_FPN_3x"
								},
								{
									value: "COCO-Detection/faster_rcnn_R_50_FPN_1x.yaml",
									name: "Bounding Boxes - faster_rcnn_R_50_FPN_1x"
								},
								{
									value: "COCO-Detection/faster_rcnn_R_101_FPN_3x.yaml",
									name: "Bounding Boxes - faster_rcnn_R_101_FPN_3x"
								}
							]
						},
						{
							key: "roi_heads",
							type: "compound",
							children: [
								{
									key: "batch_size_per_image",
									type: "number",
									default: 512
								},
								{
									key: "score_thresh_test",
									type: "number",
									default: 0.5
								}
							]
						}
					]
				},
				{
					key: "dataloader",
					type: "compound",
					children: [{
						key: "num_workers",
						type: "number",
						default: 2
					}]
				},
				{
					key: "solver",
					type: "compound",
					children: [
						{
							key: "ims_per_batch",
							type: "number",
							default: 2
						},
						{
							key: "base_lr",
							type: "number",
							default: 0.0025
						},
						{
							key: "max_iter",
							type: "number",
							default: 4500
						},
						{
							key: "steps",
							type: "array",
							style: "ROW",
							children: "number",
							default: [3000,4000]
						}
					]
				},
				{
					key: "random_erasing",
					name: "Enable random erasing",
					type: "toggle",
					default: false
				},
				{
					key: "augmentations",
					type: "array",
					style: "ROW",
					hidden: json => !json.config?.model?.base?.startsWith('COCO-Detection'),
					children: {
						type: (value)=>new AugmentationInput(value)
					}
				},
				{
					key: "random_crop",
					name: "Random crop",
					type: "compound",
					children: [
						{
							key: "enabled",
							type: "toggle",
							default: false
						},
						{
							key: "type",
							type: "list",
							options: [
								{value: "relative", name: "relative"},
								{value: "relative_range", name: "relative_range"},
								{value: "absolute", name: "absolute"},
								{value: "absolute_range", name: "absolute_range"},
							],
							default: "relative_range"
						},
						{
							key: "size",
							type: "array",
							children: "number",
							style: "ROW",
							default: [0.25, 0.25]
						}
					]
				}
			]
		},

	];
}

async function NewModel(datasetuuid, possibleTags, modelJson=null){
	let dataset = await API.dataset(datasetuuid).get();

	return new Promise(async resolve=>{
		let formJson = {...modelJson} ?? {};
		formJson['tags'] = {
			// config for tags form control
			'possible': possibleTags,
			'selected': modelJson?.tags ?? []
		};

		formJson["name"] = dataset.name;

		// build the form
		let form = new UI.Form(FormTemplate(dataset));
		// init with data
		await form.build(formJson);

		// form
		let augmentationButton = new UI.Button("Set Default Augmentations", async ()=>{
			//
			let json = form.json();
			json.config.augmentations = [
				{
					name: 'ColorJiggle',
					params: {
						'brightness': 0.1,
						'contrast': 0.1,
						'saturation': 0.1,
						'hue': 0.1,
						'p': 1.0
					}
				},
				{
					name: 'RandomAffine',
					params: {
						'degrees': 10.0,
						'p': 1.0
					}
				},
				{
					name: 'RandomHorizontalFlip',
					params: {
						'p': 0.75
					}
				},
				{
					name: 'RandomChannelShuffle',
					params: {
						'p': 0.75
					}
				},
				{
					name: 'RandomErasing',
					params: {
						'scale': [ 0.02, 0.33 ],
						'ratio': [ 0.3, 3.3 ],
						'value': 0,
						'p': 0.5
					}
				}
			];
			await form.build(json);
			form.querySelector('[data-element="config.augmentations"] > .value').append(augmentationButton);
		}, {icon: 'fa-magic', style: 'create'});
		form.querySelector('[data-element="config.augmentations"] > .value').append(augmentationButton);

		// push it into a modal
		let modal = new UI.Modal(form, {
			title: "New Model",
			buttons: "<ui-cancel></ui-cancel><ui-spacer></ui-spacer>",
			dismissable: false
		}).attach();

		modal['close'] = ()=>{
			resolve(null);
			modal.self.remove();
			return modal;
		};

		modal.panel.footer(
			new UI.Button("Create", async ()=>{
				let config = form.json(false);
				config.type = "Model";
				config.scheduler = "AI_SCHEDULER";

				// transform 'tags' key to format expected by API
				config['tags'] = config['tags']['selected'];

				await API.dataset(config['dataset']).createJob(config);

				resolve(null);
				modal.self.remove();
			}, {icon: 'fa-check', style: "create"})
		);
	});
}
export default NewModel;
