/** Canvas Image Utilities **/

export async function canvasToBlob(canvas, quality=0.8){
	return new Promise(res=>{
		canvas.toBlob(function(blob) {
			res(blob);
		}, "image/jpeg", quality);
	});
}

export async function resizeBlob(blob, maxDimension){
	let canvas = await blobToCanvas(blob, null, maxDimension);
	return await canvasToBlob(canvas, 1.0);
}

/**
 *
 * @param {*} blob
 * @param {HTMLCanvasElement} canvas
 */
export async function blobToCanvas(blob, canvas=null, maxDimension = null){
	return new Promise((res)=>{
		let img = new Image();
		img.onload = function(){
			let c = canvas || document.createElement("canvas");
			let ctx = c.getContext("2d");

			let width = img.naturalWidth;
			let height = img.naturalHeight;
			if(maxDimension){
				let ratio = Math.min(1.0, maxDimension/Math.max(width, height));
				width *= ratio;
				height *= ratio;
			}

			c.width =  width;
			c.height =  height;

			ctx.beginPath();
			ctx.rect(0, 0, width, height);
			ctx.fillStyle = "white";
			ctx.fill();

			ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight, 0,0, width, height);
			res(c);
		};
		img.src = URL.createObjectURL(blob);
	});
}

export async function dataToCanvas(data){
	if(!data.startsWith("data")){
		data = "data:image/png;base64," + data;
	}
	return new Promise((res)=>{
		let tag = new Image();
		tag.onload= ()=>{
			let c = document.createElement("canvas");
			let ctx = c.getContext("2d");
			c.width =  tag.naturalWidth;
			c.height =  tag.naturalHeight;
			ctx.drawImage(tag, 0, 0, tag.naturalWidth, tag.naturalHeight, 0,0, c.width, c.height);
			res(c);
		};
		tag.src = data;
	});
}

/**
 *
 * @param {*} canvas
 * @param {*} xyxy
 * @param {*} paddingPercent
 *
 * @returns {[HTMLCanvasElement, [Number,Number,Number,Number]]}
 */
export function crop(canvas, xyxy, paddingPercent){
	let width = canvas.width;
	let height = canvas.height;

	let x1 = Math.floor(Math.max(0, xyxy[0] - paddingPercent*width/100));
	let y1 = Math.floor(Math.max(0, xyxy[1] - paddingPercent*height/100));
	let x2 = Math.ceil(Math.min(width, xyxy[2] + paddingPercent*width/100));
	let y2 = Math.ceil(Math.min(height, xyxy[3] + paddingPercent*height/100));

	let x = x1;
	let y = y1;
	let w = x2-x1;
	let h = y2-y1;

	let cropped = document.createElement('canvas');
	cropped.width = x2 - x1;
	cropped.height = y2 - y1;
	cropped.getContext('2d').drawImage(canvas, x,y,w,h, 0,0,w,h);

	return [cropped, [x,y,w,h]];
}

/**
 *
 * @param {HTMLCanvasElement} sourceCanvas
 * @param {String} mask_b64
 * @param {[number,number]?} xyOffset
 *
 */
export async function maskedCanvas(sourceCanvas, mask_b64, xyOffset = [0,0]){
	let sourcePixels = sourceCanvas.getContext("2d").getImageData(0,0,sourceCanvas.width,sourceCanvas.height);

	let maskCanvas = await dataToCanvas(mask_b64);
	// output canvas
	let pixels = maskCanvas.getContext("2d").getImageData(0,0,maskCanvas.width,maskCanvas.height);
	let bounds = {
		minX: Infinity,
		maxX: -Infinity,
		minY: Infinity,
		maxY: -Infinity
	};

	for(let x = 0; x < maskCanvas.width; x++){
		for(let y = 0; y < maskCanvas.height; y++){
			let i = (x + y * maskCanvas.width)*4;
			let i2 = (xyOffset[0]+x + (xyOffset[1]+y) * sourceCanvas.width)*4;
			if(pixels.data[i] > 0.9){
				pixels.data[i+0] = sourcePixels.data[i2+0];
				pixels.data[i+1] = sourcePixels.data[i2+1];
				pixels.data[i+2] = sourcePixels.data[i2+2];
				pixels.data[i+3] = 255;

				bounds.minX = Math.min(x, bounds.minX);
				bounds.minY = Math.min(y, bounds.minY);
				bounds.maxX = Math.max(x, bounds.maxX);
				bounds.maxY = Math.max(y, bounds.maxY);
			}else{
				pixels.data[i+3] = 0;
			}
		}
	}

	maskCanvas.width = bounds.maxX - bounds.minX;
	maskCanvas.height = bounds.maxY - bounds.minY;
	maskCanvas.getContext("2d").putImageData(pixels, -bounds.minX, -bounds.minY);

	let overlay = document.createElement('canvas');
	overlay.width = sourcePixels.width;
	overlay.height = sourcePixels.height;
	overlay.getContext("2d").putImageData(pixels, xyOffset[0], xyOffset[1]);

	return [maskCanvas, overlay];
}
