async function multiImageDropBehaviour(event, callback){
	event.preventDefault();
	event.stopPropagation();

	let data = event.dataTransfer;
	// attempt to read file from drop event
	if(data.files.length>0){
		await callback(data.files);
	}else{
		// no file so attempt to read markup from drop event
		let types = data.types;
		if(types.includes("text/html")){
			let markup = data.getData("text/html");
			let wrapper = document.createElement('div');
			wrapper.innerHTML = markup;
			// parse the dropped markup an find a img tag in it
			// (which will typically be inside the dragged element)
			let img = wrapper.querySelector('img');
			if(img){
				// via the internal proxy grab the image
				let resp = await fetch(img.src);
				await callback([await resp.blob()]);
			}
		}
	}
}

export default function(element, callback){
	element.addEventListener('dragover', (event)=>{
		event.preventDefault();event.stopPropagation();
	});
	element.addEventListener('drop', (event)=>multiImageDropBehaviour(event, callback));
}
