import API from '/scripts/API.js';
import * as UI from "automaton-ui";
import JobPanel from '/component/job/JobPanel.js';
import ModelEditor from '/component/models/ModelEditor.js';
import WorldFilter from '/component/global/WorldFilter.js';
import * as Utils from "/scripts/Utils.js";
import NewModel from '/component/dataset/NewModel.js';
export default class ModelList extends UI.BasicElement{

	models;

	filter;

	constructor(){
		super();

		this.init();
	}

	async init(){
		this.models = await API.model().list();

		let datasets = await API.dataset().list();
		let datasetMap = {};
		datasets.forEach(d=>datasetMap[d.uuid]=d);

		let table = new UI.Table();
		this.table = table;

		// create filter
		// add filter to UI
		let filterElementWrapper = UI.utils.htmlToElement("<header class='filter'></header>");
		this.append(filterElementWrapper);

		this.filter = await this.createFilterForm(filterElementWrapper, table);
		WorldFilter.addEventListener('change', async ()=>{
			this.filter = await this.createFilterForm(filterElementWrapper, table);
		});
		// connect filter to table
		table._filterFunc = this.createFilterFunction();

		// name
		table.addAttr(
			"Name",
			{
				value: model=>model.name ?? model.uuid,
				display: {
					filterable: "suggest"
				}
			}
		);

		// tags
		table.addAttribute(
			"Tags",
			model=>model.tags?.join(','),
			model=>model.tags?.map(t=>{
				let badge = new UI.Badge(t);
				badge.classList.add("entity-tag");

				// filter by tag when clicked
				badge.addEventListener('click', ()=>{
					this.filter.build({label:t});
					table.filter(table._filterFunc);
				});

				return badge;
			}) ?? '-',
			"20%");

		// framework
		table.addAttr('framework', {display: {filterable: "suggest"}});
		table.addAttribute('Base', (j)=>{
			let s = j?.config?.base ?? j?.config?.model_loader ?? j?.config?.model?.base ?? "";
			return s
				.replace("models.", "")
				.replace("(pretrained=True)", "")
				.replace("COCO-Detection/", "")
				.replace("COCO-InstanceSegmentation/", "")
				.replace(".yaml", "")
				.replace(".pt", "");
		});

		// dataset
		table.addAttr(
			'Dataset',
			{
				value: (j)=>datasetMap[j.dataset].name ?? datasetMap[j.dataset].uuid,
				render: (j) => `<a href="${Utils.getUrl('dataset', j.dataset)}">${datasetMap[j.dataset].name ?? j.dataset}<a/>`,
				display: {
					filterable: "suggest"
				}
			});

		// Deployment
		table.addAttr("Production", {
			value: (model)=>model.production?`${model.production.name}:${model.production.version}`:'',
			display: {
				filterable: "suggest"
			}
		});

		// classes
		table.addAttribute(
			'Classes',
			model=>Object.keys(model.classes).join(','),
			model=>Object.keys(model.classes).map(s=>new UI.Badge(s)),
			"20%");

		// status
		table.addAttribute(
			'Status',
			(m)=>''+m.ready,
			(m)=> m.ready?'<i class="icon fa fa-check success"></i>':'<i class="icon fa fa-hourglass pending"></i>');

		// accuracy
		table.addAttribute(
			'Accuracy',
			(model)=> {
				if(model.validationAccuracy !== undefined){
					return model.validationAccuracy;
				}
				return model.accuracy;
			},
			(model)=>model.validationAccuracy !== undefined?`<h3><i>VAL</i> ${model.validationAccuracy.toFixed(1)}%</h3>`:model.accuracy !== undefined?`<h3>${model.accuracy}</h3>`:'<h3></h3>');

		// actions
		table.addAttribute(
			'Actions',
			model=>model.created,
			model=>this.createActions(model));

		// add contextual click to items
		let rowFunc = table.renderItem.bind(table);
		table.renderItem = async (item)=>{
			let ele = await rowFunc(item);
			this.addContextMenu(ele, item);
			return ele;
		};

		// final table config and add to UI
		table.sort('Actions', false);
		table.data = this.models;

		await WorldFilter.apply(table);

		table.render();
		this.append(table);

		// update view every 60 seconds
		this.setInterval(this.update.bind(this), 60_000);
	}

	async update(){
		let models = await API.model().list();
		this.table.data = models;
		this.table.render();
	}

	createActions(model){
		/** @type {HTMLElement[]} */
		let actions = [
			new UI.Button("View",
				Utils.getUrl('model', model.uuid),
				{icon: 'fa-search'}),
			new UI.Button('Logs', ()=>{
				new UI.Modal(new JobPanel(model.job)).show();
			}, {icon: "fa-bars"})
		];
		if (model.ready) {
			if(model.modelServiceEnabled){
				// newer models have different mechanisms for deployment
				// todo which we shoul dmake very easy
			}else{
				// legacy model mechanisms
				if (model.deployment) {
					actions.push(new UI.Button("Teardown", async ()=>{
						await API.model(model.uuid).teardown();
						this.update();
					}, {style: "delete", icon: "fas fa-power-off"}));

					actions.push(
						UI.utils.htmlToElement(`<div>Running at <b>${model.deployment['host']}</b></div>`)
					);
				} else {
					actions.push(new UI.Button("Deploy API", async ()=>{
						await API.model(model.uuid).createTestDeployment();
						this.update();
					}, {style: "create", icon: "fas fa-server"}));
				}
				if (!model.persistedDeploymentFiles){
					actions.push(new UI.Button("Persist Files", async ()=>{
						await API.model(model.uuid).persistFiles();
						this.update();
					}, {style: "create", icon: "fas fa-server"}));
				}
			}
		}

		let controls = new UI.BasicElement();
		UI.utils.append(controls, actions);
		controls.append(UI.utils.htmlToElement(`
			<sub>
				by <b>${model.author.replace('@snaptech.ai', '')}</b>
				at <b>${new Date(model.created).toISOString().replaceAll(/[TZ]/g, ' ').substring(0,16)}</b>
			</sub>`));

		return controls;
	}

	addContextMenu(element, item){
		let menu = new UI.ContextMenu();
		let url = Utils.getUrl('model', item.uuid);
		menu.addItem("View", ()=>location.href = url);
		menu.addItem("View (new tab)", ()=>window.open(url));
		menu.addItem("New Model", async ()=>NewModel(item.dataset, await this.getPossibleTags(this.models), item));
		menu.addItem("Edit", async ()=>new ModelEditor(item.uuid, await this.getPossibleTags(this.models)).show());
		if(!item.production){
			menu.addBreak();
			menu.addItem("Delete", async ()=>{
				// give the user an option to bail
				if(!confirm("Really delete Model (including attached Jobs and Evaluations?")) {
					return;
				}
				await API.model(item.uuid).delete();
				new UI.Toast("Model deleted");
				element.remove();
			});
		}
		menu.for(element);
		return menu;
	}

	static ANY_CLASS = "-";

	async createFilterForm(wrapper, table){
		// keep the previous value if there is one
		let value = {};
		if(this.filter!=null) {
			value = this.filter.value;
		}


		let tags = await this.getPossibleTags(this.models);

		// generate the form
		let tagValues = tags.map((t)=>({value:t, name:t}));
		let values = [
			{value: ModelList.ANY_CLASS, name: 'ANY'},
			...tagValues
		];
		let filterForm = new UI.Form([{
			key: 'label',
			name: "<b>Tag</b>",
			type: 'list',
			format: 'string',
			options: ()=>values
		}]);
		filterForm.formStyle = UI.Form.STYLE.INLINE;

		// generate the form
		await filterForm.build(tags.length>0?value:{});
		// hide if there are no options
		if(tags.length==0) {
			filterForm.hide();
		}

		// update the filter if we change
		filterForm.addEventListener('change', ()=>{
			table.filter(table._filterFunc);
		});

		// add the form to the DOM
		wrapper.innerHTML = "";
		wrapper.append(filterForm);

		return filterForm;
	}

	createFilterFunction(){
		return (model)=>{
			let filterObject = this.filter.value;

			if(filterObject.label == ModelList.ANY_CLASS) {
				return true;
			}

			for(let tag of model.tags ?? []){
				if (tag == filterObject.label){
					return true;
				}
			}
			return false;
		};
	}

	async getPossibleTags(models){
		// make sure the WorldFilter has generated
		await WorldFilter.ready();

		// fetch a list of valid tags for this page
		models = models.filter((m)=>WorldFilter.filter(m));

		let tags = [...new Set(models.flatMap(m=>m.tags??[]))];
		tags.sort();
		return tags;
	}

}
window.customElements.define('ui-modellist', ModelList);
