import * as UI from "automaton-ui";
import API from "/scripts/API.js";
import * as Utils from "/scripts/Utils.js";

/**
 * Detail panel for a World
 */
class WorldConfig extends UI.BasicElement{

	constructor(uuid){
		super();

		this.uuid = uuid;

		this.init();
	}

	async init(){
		let json = await API.world(this.uuid).get();
		let panel = new UI.Panel(``, {title: `'${json.name}' World`});
		this.append(panel);

		let collections = (await API.collection().list()).sort(Utils.sortByName);
		let collectionsValues = collections.map(set=>{
			return {name: set.name, value: set.uuid};
		});

		let form = new UI.Form([
			{
				key :"name",
				type: "string"
			},
			{
				key :"description",
				type: "text"
			},
			{
				"key": "collections",
				"type": "array",
				"children": [{
					"type":"list",
					"options": collectionsValues
				}]
			}
		]);
		form.build(JSON.parse(JSON.stringify(json)));
		panel.append(form);

		panel.append(new UI.Button("Update", async ()=>{
			let data = form.json();

			// copy in current exports
			data['exports'] = json['exports'];

			await API.world(this.uuid).update(data);
			location.reload();
		}));
	}

}

window.customElements.define("ui-worldconfig", WorldConfig);
export default WorldConfig;