import ItemEvaluationPanel from "/component/evaluation/ItemEvaluation.js";
import UI from "automaton-ui";

export default class EvaluationResults extends UI.BasicElement{

	constructor(mode, results){
		super();

		this.mode = mode;
		this.results = results;

		this.init();
	}

	async init(){
		// build a form to configure filters
		let filterForm = new UI.Form([
		]);
		filterForm.formStyle = UI.Form.STYLE.INLINE;
		await filterForm.build({});

		// add the filer header to the element
		let filterElement = UI.utils.htmlToElement("<header class='filter'></header>");
		filterElement.append(filterForm);
		this.append(filterElement);

		// create a function that filters an item based on our configuration
		let filterFunction = (item)=>{
			// let filterObject = filterForm.value;
			return true;
		};

		// create a list of the items
		let list = new UI.List((item)=>new ItemEvaluationPanel(this.mode, item, null), {itemColumns: 3, itemsPerPage: 12});
		list.data = this.results;
		// odd value function as sorting base16 numbers isn't something UI.List handles well!
		list.addAttribute("UUID", item=>parseInt(item.uuid.split('-')[0],16));
		list.addAttribute("Annotation count", item=>item.annotations.length);

		//await list.filter(filterFunction);

		list.sort("UUID", true);
		this.append(list);

		// wire the filter to update whenever the form changes
		filterForm.addEventListener('change', ()=>{
			list.filter(filterFunction);
		});
	}

}

window.customElements.define("ui-evaluationresults", EvaluationResults);
